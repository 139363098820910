import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/payments';
import { LocalStorageItem } from '../../enums/storage';

var completedOrderIdsFromLocalStorage = function completedOrderIdsFromLocalStorage() {
  var completedOrders = typeof localStorage !== 'undefined' ? JSON.parse(localStorage.getItem(LocalStorageItem.ALL_COMPLETED_ORDERS) || '[]') : [];
  return completedOrders;
};

var INITIAL_STATE = {
  loading: false,
  paymentSources: {},
  error: '',
  info: '',
  orders: {},
  completedOrderIds: completedOrderIdsFromLocalStorage()
};
export var PaymentReducer = function PaymentReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.GET_PAYMENT_SOURCES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_PAYMENT_SOURCES_SUCCESS:
      {
        var paymentSourceList = _toConsumableArray(action.payload.data);

        var paymentSources = {};
        paymentSourceList.forEach(function (source) {
          return paymentSources[source.id] = source;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          paymentSources: paymentSources
        });
      }

    case definitions.GET_PAYMENT_SOURCES_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.DELETE_PAYMENT_SOURCE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.DELETE_PAYMENT_SOURCE_SUCCESS:
      {
        var deleted = action.payload.deleted;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: deleted ? 'Card Deleted' : ''
        });
      }

    case definitions.DELETE_PAYMENT_SOURCE_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_PAYMENT_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_PAYMENT_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_PAYMENT_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.CLEAR_PAYMENT_DATA:
      {
        return INITIAL_STATE;
      }

    case definitions.GET_ORDER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_ORDER_SUCCESS:
      {
        var orders = _objectSpread({}, state.orders);

        var order = action.payload;
        orders[order.id] = order;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          orders: orders
        });
      }

    case definitions.GET_ORDER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_ORDER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_ORDER_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false
      });

    case definitions.UPDATE_ORDER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_SUBSCRIPTION_ORDER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.INIT_ORDER_PAYMENT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.INIT_ORDER_PAYMENT_SUCCESS:
      {
        var completedOrderIds = _toConsumableArray(state.completedOrderIds);

        completedOrderIds.push(action.payload.id);
        if (typeof localStorage !== 'undefined') localStorage.setItem(LocalStorageItem.ALL_COMPLETED_ORDERS, JSON.stringify(completedOrderIds));
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          completedOrderIds: completedOrderIds
        });
      }

    case definitions.INIT_ORDER_PAYMENT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    default:
      return state;
  }
};