import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/provider';
import { getServiceIdFromUrl } from '../../utils/helpers';
var INITIAL_STATE = {
  loading: false,
  testEmailLoading: false,
  processing: false,
  serviceProspectVerificationSent: false,
  surveyAnswersSubmitted: false,
  postsEmailSent: false,
  emailSessionAttendees: false,
  testPostEmailSent: false,
  error: '',
  info: '',
  providers: {},
  providerServices: {},
  providerSelectedServiceId: null,
  serviceProspects: {},
  serviceProspectCount: 0,
  planProspects: {},
  serviceProspectList: [],
  posts: {},
  surveys: {},
  emailCampaigns: {},
  schedules: {},
  plans: {},
  subscriptions: {},
  serviceCustomers: {},
  serviceCustomersPromoTrue: [],
  serviceTerms: {},
  activeReferralPrograms: {},
  referralPrograms: {},
  servicePostMailingList: {},
  tooltips: {},
  surveyResponsesPerPlan: {},
  revenueReport: {}
};
export var ProviderReducer = function ProviderReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.GET_PROVIDER_SERVICE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_PROVIDER_SERVICE_SUCCESS:
      {
        var providerServices = _objectSpread({}, state.providerServices);

        providerServices[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providerServices: providerServices
        });
      }

    case definitions.PROVIDER_DID_CHANGE:
      {
        var providers = _objectSpread({}, state.providers);

        providers[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providers: providers
        });
      }

    case definitions.PROVIDER_SERVICE_DID_CHANGE:
      {
        var _providerServices = _objectSpread({}, state.providerServices);

        _providerServices[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providerServices: _providerServices
        });
      }

    case definitions.GET_PROVIDER_SERVICE_FAIL:
      {
        var _providerServices2 = _objectSpread({}, state.providerServices);

        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providerServices: _providerServices2,
          error: action.payload
        });
      }

    case definitions.CREATE_SERVICE_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_SERVICE_PROSPECT_SUCCESS:
      {
        var serviceProspects = _objectSpread({}, state.serviceProspects);

        serviceProspects[action.payload.id] = action.payload;
        serviceProspects['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceProspects: serviceProspects
        });
      }

    case definitions.CREATE_SERVICE_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_SERVICE_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_SERVICE_PROSPECT_SUCCESS:
      {
        var _serviceProspects = _objectSpread({}, state.serviceProspects);

        var serviceProspectList = _toConsumableArray(action.payload);

        _serviceProspects['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceProspects: _serviceProspects,
          serviceProspectList: serviceProspectList,
          info: 'Leads deleted successfully'
        });
      }

    case definitions.UPDATE_SERVICE_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CONFIRM_SERVICE_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true,
          serviceProspectVerificationSent: true
        });
      }

    case definitions.CONFIRM_SERVICE_PROSPECT_SUCCESS:
      {
        var _provider$user;

        var _serviceProspects2 = _objectSpread({}, state.serviceProspects);

        var _action$payload = action.payload,
            provider = _action$payload.provider,
            serviceProspect = _action$payload.serviceProspect,
            skipNotification = _action$payload.skipNotification;
        _serviceProspects2[serviceProspect.id] = serviceProspect;
        _serviceProspects2['current'] = serviceProspect;
        var providerService = serviceProspect.providerService;

        var retval = _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceProspects: _serviceProspects2
        });

        if (!skipNotification) retval.info = "Confirmed! You'll now receive emails from ".concat(providerService.name, " by ").concat((_provider$user = provider.user) === null || _provider$user === void 0 ? void 0 : _provider$user.firstName);
        return retval;
      }

    case definitions.CONFIRM_SERVICE_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_PLAN_PROSPECTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_PLAN_PROSPECTS_SUCCESS:
      {
        var planProspects = _objectSpread({}, state.planProspects);

        action.payload.forEach(function (p) {
          return planProspects[p.id] = p;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          planProspects: planProspects
        });
      }

    case definitions.GET_PLAN_PROSPECTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_PLAN_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_PLAN_PROSPECT_SUCCESS:
      {
        var planProspect = action.payload;

        var _planProspects = _objectSpread({}, state.planProspects);

        _planProspects[planProspect.id] = planProspect;
        _planProspects['current'] = planProspect;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          planProspects: _planProspects,
          serviceProspectVerificationSent: true // TODO: bad name for var - we just want to stop the redirect

        });
      }

    case definitions.CREATE_PLAN_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_PROVIDER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_PROVIDER_SUCCESS:
      {
        var _providers = _objectSpread({}, state.providers);

        var _providerServices3 = _objectSpread({}, state.providerServices);

        _providers[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providers: _providers,
          providerServices: _providerServices3
        });
      }

    case definitions.LOGGED_IN_PROVIDER_SUCCESS:
      {
        var _providers2 = _objectSpread({}, state.providers);

        var _providerServices4 = {};

        if (action.payload.services) {
          action.payload.services.forEach(function (s) {
            _providerServices4[s.id] = s;
          });
        }

        _providers2[action.payload.id] = action.payload;

        var _retval = _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providers: _providers2,
          providerServices: _providerServices4
        });

        var serviceIdInUrl = getServiceIdFromUrl(); // Where we set the initial / default Provider Service (providerSelectedServiceId)

        var currentServiceBasedOnUrl = action.payload.services.find(function (service) {
          return service.id === serviceIdInUrl;
        });
        var currentService = currentServiceBasedOnUrl !== null && currentServiceBasedOnUrl !== void 0 ? currentServiceBasedOnUrl : action.payload.services[0];
        if (currentService !== null && currentService !== void 0 && currentService.id) _retval.providerSelectedServiceId = currentService.id;
        return _retval;
      }

    case definitions.GET_PROVIDER_FAIL:
      {
        var _providers3 = _objectSpread({}, state.providers);

        var _providerServices5 = _objectSpread({}, state.providerServices);

        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          providers: _providers3,
          providerServices: _providerServices5,
          error: action.payload
        });
      }

    case definitions.GET_SURVEY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SURVEY_SUCCESS:
      {
        var surveys = _objectSpread({}, state.surveys);

        surveys[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          surveys: surveys
        });
      }

    case definitions.GET_SURVEY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_PLAN_SURVEY_ANSWERS_SUCCESS:
      {
        var newVal = _objectSpread({}, state.surveyResponsesPerPlan);

        newVal[action.payload.planId] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          surveyResponsesPerPlan: newVal
        });
      }

    case definitions.SUBMIT_SURVEY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          surveyAnswersSubmitted: true,
          loading: true
        });
      }

    case definitions.SUBMIT_SURVEY_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case definitions.SUBMIT_SURVEY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_SURVEY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_SURVEY_SUCCESS:
      {
        var _surveys = _objectSpread({}, state.surveys);

        _surveys[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          surveys: _surveys,
          loading: false,
          info: 'Survey created successfully'
        });
      }

    case definitions.CREATE_SURVEY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_SURVEY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_SURVEY_SUCCESS:
      {
        var _surveys2 = _objectSpread({}, state.surveys);

        _surveys2[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          surveys: _surveys2,
          loading: false,
          info: 'Survey updated successfully'
        });
      }

    case definitions.UPDATE_SURVEY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_POSTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          postsLoading: true
        });
      }

    case definitions.GET_POSTS_SUCCESS:
      {
        var postList = _toConsumableArray(action.payload);

        var posts = {};
        postList.forEach(function (p) {
          return posts[p.id] = p;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          postsLoading: false,
          posts: posts
        });
      }

    case definitions.GET_POSTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          postsLoading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_PROSPECTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SERVICE_PROSPECTS_SUCCESS:
      {
        var _serviceProspectList = _toConsumableArray(action.payload);

        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceProspectList: _serviceProspectList
        });
      }

    case definitions.GET_SERVICE_PROSPECTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_PROSPECT_COUNT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          serviceProspectCount: action.payload
        });
      }

    case definitions.GET_POST_SUCCESS:
      {
        var _posts = _objectSpread({}, state.posts);

        _posts[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          posts: _posts
        });
      }

    case definitions.CREATE_POST_SUCCESS:
      {
        var _posts2 = _objectSpread({}, state.posts);

        _posts2[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          posts: _posts2
        });
      }

    case definitions.CREATE_POST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_POST_PUBLISH_STATUS_SUCCESS:
      {
        var _action$payload2;

        var _posts3 = _objectSpread({}, state.posts);

        _posts3[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          posts: _posts3,
          info: action !== null && action !== void 0 && (_action$payload2 = action.payload) !== null && _action$payload2 !== void 0 && _action$payload2.published ? 'Post Published Successfully.' : ''
        });
      }

    case definitions.UPDATE_POST_SUCCESS:
      {
        var _posts4 = _objectSpread({}, state.posts);

        _posts4[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          posts: _posts4
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          posts: _posts4
        });
      }

    case definitions.UPDATE_POST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.DELETE_POST_SUCCESS:
      {
        var _posts5 = _objectSpread({}, state.posts);

        delete _posts5[action.payload.id];
        return _objectSpread(_objectSpread({}, state), {}, {
          posts: _posts5
        });
      }

    case definitions.EMAIL_POST_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.EMAIL_POST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          postsEmailSent: true,
          loading: false
        });
      }

    case definitions.EMAIL_POST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.EMAIL_SESSION_ATTENDEES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.EMAIL_SESSION_ATTENDEES_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          postsEmailSent: true,
          loading: false,
          info: 'Email sent'
        });
      }

    case definitions.EMAIL_SESSION_ATTENDEES_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.TEST_EMAIL_POST_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          testEmailLoading: true
        });
      }

    case definitions.TEST_EMAIL_POST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          testPostEmailSent: true,
          testEmailLoading: false
        });
      }

    case definitions.TEST_EMAIL_POST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          testEmailLoading: false,
          error: action.payload
        });
      }

    case definitions.EMAIL_POST_RESET:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          postsEmailSent: false,
          testPostEmailSent: false
        });
      }

    case definitions.CLEAR_PROVIDER_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_PROVIDER_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_PROVIDER_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.CREATE_EMAIL_CAMPAIGN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_EMAIL_CAMPAIGN_SUCCESS:
      {
        var emailCampaigns = _objectSpread({}, state.emailCampaigns);

        emailCampaigns[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          emailCampaigns: emailCampaigns,
          info: 'Email sent successfully'
        });
      }

    case definitions.CREATE_EMAIL_CAMPAIGN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_EMAIL_CAMPAIGN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_EMAIL_CAMPAIGN_SUCCESS:
      {
        var _emailCampaigns = _objectSpread({}, state.emailCampaigns);

        _emailCampaigns[action.payload.id] = action.payload;
        _emailCampaigns['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          emailCampaigns: _emailCampaigns
        });
      }

    case definitions.GET_EMAIL_CAMPAIGN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SURVEY_RESULTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_EMAIL_CAMPAIGN_LIST_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_EMAIL_CAMPAIGN_LIST_SUCCESS:
      {
        var emailCampaignList = _toConsumableArray(action.payload);

        var _emailCampaigns2 = {};
        emailCampaignList.forEach(function (p) {
          return _emailCampaigns2[p.id] = p;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          emailCampaigns: _emailCampaigns2
        });
      }

    case definitions.GET_EMAIL_CAMPAIGN_LIST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SURVEY_RESULTS_SUCCESS:
      {
        var _surveys3 = _objectSpread({}, state.surveys);

        _surveys3[action.payload.id] = action.payload;
        _surveys3['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          surveys: _surveys3
        });
      }

    case definitions.GET_SURVEY_RESULTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_ALL_SURVEY_RESULTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_ALL_SURVEY_RESULTS_SUCCESS:
      {
        var surveyList = _toConsumableArray(action.payload);

        var _surveys4 = {};
        surveyList.forEach(function (s) {
          return _surveys4[s.id] = s;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          surveys: _surveys4
        });
      }

    case definitions.GET_ALL_SURVEY_RESULTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SCHEDULE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          schedulesLoading: true
        });
      }

    case definitions.GET_SCHEDULE_SUCCESS:
      {
        var scheduleList = _toConsumableArray(action.payload);

        var schedules = {};
        scheduleList.forEach(function (s) {
          // Use the start time as an ID since recurring activities share the same ID
          // And we don't want to clobber the records
          schedules[s.startsAt] = s;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          schedulesLoading: false,
          schedules: schedules
        });
      }

    case definitions.GET_SCHEDULE_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          schedulesLoading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_PLANS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.GET_SERVICE_PLANS_SUCCESS:
      {
        var plans = {};
        action.payload.forEach(function (p) {
          return plans[p.plan.id] = _objectSpread(_objectSpread({}, p.plan), {}, {
            invoicePreview: p.invoicePreview
          });
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          plans: plans
        });
      }

    case definitions.GET_SERVICE_PLANS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.GET_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.GET_PLAN_SUCCESS:
      {
        var _plans = _objectSpread({}, state.plans);

        _plans[action.payload.plan.id] = _objectSpread(_objectSpread({}, action.payload.plan), {}, {
          invoicePreview: action.payload.invoicePreview
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          plans: _plans,
          plansLoading: false
        });
      }

    case definitions.GET_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.CREATE_PLAN_SUCCESS:
      {
        var _plans2 = _objectSpread({}, state.plans);

        _plans2[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          info: 'Plan saved successfully',
          plans: _plans2
        });
      }

    case definitions.CREATE_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.UPDATE_PLAN_SUCCESS:
      {
        var _plans3 = _objectSpread({}, state.plans);

        _plans3[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          plans: _plans3,
          plansLoading: false,
          info: 'Plan updated successfully'
        });
      }

    case definitions.UPDATE_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.ACTIVATE_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.ACTIVATE_PLAN_SUCCESS:
      {
        var _plans4 = _objectSpread({}, state.plans);

        _plans4[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          plans: _plans4,
          plansLoading: false,
          info: 'Subscription plan has been activated successfully.'
        });
      }

    case definitions.ACTIVATE_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.DEACTIVATE_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.DEACTIVATE_PLAN_SUCCESS:
      {
        var _plans5 = _objectSpread({}, state.plans);

        _plans5[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          plans: _plans5,
          plansLoading: false,
          info: 'Subscription plan has been deactivated successfully.'
        });
      }

    case definitions.DEACTIVATE_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.DELETE_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: true
        });
      }

    case definitions.DELETE_PLAN_SUCCESS:
      {
        var _plans6 = _objectSpread({}, state.plans);

        delete _plans6[action.payload.id];
        return _objectSpread(_objectSpread({}, state), {}, {
          plans: _plans6,
          info: 'Plan deleted successfully',
          plansLoading: false
        });
      }

    case definitions.DELETE_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          plansLoading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_SUBSCRIPTIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SERVICE_SUBSCRIPTIONS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          subscriptions: action.payload
        });
      }

    case definitions.GET_SERVICE_SUBSCRIPTIONS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_PROMO_TRUE_SERVICE_CUSTOMERS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_PROMO_TRUE_SERVICE_CUSTOMERS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceCustomersPromoTrue: action.payload
        });
      }

    case definitions.GET_PROMO_TRUE_SERVICE_CUSTOMERS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_CUSTOMERS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SERVICE_CUSTOMERS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceCustomers: action.payload
        });
      }

    case definitions.GET_SERVICE_CUSTOMERS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_TERMS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          serviceTermsLoading: true
        });
      }

    case definitions.GET_SERVICE_TERMS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          serviceTermsLoading: false,
          serviceTerms: action.payload
        });
      }

    case definitions.GET_SERVICE_TERMS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          serviceTermsLoading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_VERIFIED_SERVICE_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_VERIFIED_SERVICE_PROSPECT_SUCCESS:
      {
        var _serviceProspects3 = _objectSpread({}, state.serviceProspects);

        var _serviceProspectList2 = state.serviceProspectList.concat([action.payload]);

        _serviceProspects3[action.payload.id] = action.payload;
        _serviceProspects3['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Lead saved successfully',
          serviceProspects: _serviceProspects3,
          serviceProspectList: _serviceProspectList2
        });
      }

    case definitions.CREATE_VERIFIED_SERVICE_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPLOAD_SERVICE_PROSPECTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPLOAD_SERVICE_PROSPECTS_SUCCESS:
      {
        var _serviceProspectList3 = [].concat(_toConsumableArray(state.serviceProspectList), _toConsumableArray(action.payload));

        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Leads saved successfully',
          serviceProspectList: _serviceProspectList3
        });
      }

    case definitions.UPLOAD_SERVICE_PROSPECTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_PROVIDER_DATA:
      {
        return INITIAL_STATE;
      }

    case definitions.UPDATE_PROVIDER_SERVICE_SUCCESS:
      {
        var _providerServices6 = _objectSpread({}, state.providerServices);

        var _action$payload3 = action.payload,
            _providerService = _action$payload3.providerService,
            message = _action$payload3.message;
        _providerServices6[_providerService.id] = _providerService;
        return _objectSpread(_objectSpread({}, state), {}, {
          providerServices: _providerServices6,
          loading: false,
          info: message ? message : 'Service updated successfully'
        });
      }

    case definitions.UPDATE_PROVIDER_SERVICE_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_PROVIDER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_PROVIDER_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case definitions.CREATE_PROVIDER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_PROVIDER_SERVICE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_PROVIDER_SERVICE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          providerSelectedServiceId: action.payload.id,
          loading: false
        });
      }

    case definitions.CREATE_PROVIDER_SERVICE_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_PROVIDER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_PROVIDER_SUCCESS:
      {
        var _providers4 = _objectSpread({}, state.providers);

        _providers4[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Provider info updated successfully'
        });
      }

    case definitions.UPDATE_PROVIDER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.SET_PROVIDER_SELECTED_SERVICE_ID:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          providerSelectedServiceId: action.payload
        });
      }

    case definitions.GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS:
      {
        var _action$payload4;

        var referralPrograms = _objectSpread({}, state.activeReferralPrograms);

        if ((_action$payload4 = action.payload) !== null && _action$payload4 !== void 0 && _action$payload4.serviceId) {
          referralPrograms[action.payload.serviceId] = action.payload;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          activeReferralPrograms: referralPrograms
        });
      }

    case definitions.GET_ACTIVE_REFERRAL_PROGRAM_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SERVICE_MAILING_LIST_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_SERVICE_TERMS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SERVICE_MAILING_LIST_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          servicePostMailingList: _objectSpread(_objectSpread({}, state.servicePostMailingList), action.payload)
        });
      }

    case definitions.GET_SERVICE_MAILING_LIST_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CREATE_SERVICE_TERMS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceTerms: action.payload,
          info: 'Terms published successfully.'
        });
      }

    case definitions.CREATE_SERVICE_TERMS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_SERVICE_TERMS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_SERVICE_TERMS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          serviceTerms: action.payload,
          info: 'Terms published successfully.'
        });
      }

    case definitions.UPDATE_SERVICE_TERMS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_REVENUE_REPORT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_REVENUE_REPORT_SUCCESS:
      {
        var revenueReport = _objectSpread({}, state.revenueReport);

        revenueReport[action.payload.serviceId] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          revenueReport: revenueReport
        });
      }

    case definitions.GET_REVENUE_REPORT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    default:
      return state;
  }
};