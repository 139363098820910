export var CREATE_ACTIVITY_REQUEST = 'CREATE_ACTIVITY_REQUEST';
export var CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export var CREATE_ACTIVITY_FAIL = 'CREATE_ACTIVITY_FAIL';
export var CLEAR_SESSION_ERRORS = 'CLEAR_SESSION_ERRORS';
export var SET_SESSION_INFO = 'SET_SESSION_INFO';
export var CLEAR_SESSION_INFO = 'CLEAR_SESSION_INFO';
export var SET_ACTIVITY_LOCATION = 'SET_ACTIVITY_LOCATION';
export var GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export var GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export var GET_ACTIVITIES_FAIL = 'GET_ACTIVITIES_FAIL';
export var DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST';
export var DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export var DELETE_ACTIVITY_FAIL = 'DELETE_ACTIVITY_FAIL';
export var UPDATE_ACTIVITY_REQUEST = 'UPDATE_ACTIVITY_REQUEST';
export var UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export var UPDATE_ACTIVITY_FAIL = 'UPDATE_ACTIVITY_FAIL';
export var CREATE_SESSION_REQUEST = 'CREATE_SESSION_REQUEST';
export var CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export var CREATE_SESSION_FAIL = 'CREATE_SESSION_FAIL';
export var UPDATE_SESSION_REQUEST = 'UPDATE_SESSION_REQUEST';
export var UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export var UPDATE_SESSION_FAIL = 'UPDATE_SESSION_FAIL';
export var GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export var GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export var GET_SESSION_FAIL = 'GET_SESSION_FAIL';
export var GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST';
export var GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export var GET_ACTIVITY_FAIL = 'GET_ACTIVITY_FAIL';
export var CLEAR_SESSION_DATA = 'CLEAR_SESSION_DATA';
export var GET_SESSION_ACCESS_REQUEST = 'GET_SESSION_ACCESS_REQUEST';
export var GET_SESSION_ACCESS_SUCCESS = 'GET_SESSION_ACCESS_SUCCESS';
export var GET_SESSION_ACCESS_FAIL = 'GET_SESSION_ACCESS_FAIL';
export var GET_UPCOMING_SESSIONS_REQUEST = 'GET_UPCOMING_SESSIONS_REQUEST';
export var GET_UPCOMING_SESSIONS_SUCCESS = 'GET_UPCOMING_SESSIONS_SUCCESS';
export var GET_UPCOMING_SESSIONS_FAIL = 'GET_UPCOMING_SESSIONS_FAIL';
export var SET_CURRENT_SESSION = 'SET_CURRENT_SESSION';