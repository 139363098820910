import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/session';
var INITIAL_STATE = {
  loading: false,
  processing: false,
  error: '',
  info: '',
  activities: {},
  location: null,
  sessions: {},
  subscriptions: {},
  sessionAccess: {},
  upcomingSessions: {},
  currentEvent: {}
};
export var SessionReducer = function SessionReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.CREATE_ACTIVITY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_ACTIVITY_SUCCESS:
      {
        var activities = _objectSpread({}, state.activities);

        activities[action.payload.id] = action.payload;
        activities['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Activity Created',
          activities: activities
        });
      }

    case definitions.CREATE_ACTIVITY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_ACTIVITY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.DELETE_ACTIVITY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_ACTIVITIES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_ACTIVITIES_SUCCESS:
      {
        var activityList = _toConsumableArray(action.payload);

        var _activities = {};
        activityList.forEach(function (a) {
          return _activities[a.startsAt] = a;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          activities: _activities
        });
      }

    case definitions.GET_SESSION_ACCESS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SESSION_ACCESS_SUCCESS:
      {
        var sessionAccess = _objectSpread({}, state.sessionAccess);

        sessionAccess[action.payload.context.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          sessionAccess: sessionAccess
        });
      }

    case definitions.DELETE_ACTIVITY_SUCCESS:
      {
        var _activities2 = _objectSpread({}, state.activities);

        delete _activities2[action.payload.id];
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Activity Deleted',
          activities: _activities2
        });
      }

    case definitions.UPDATE_ACTIVITY_SUCCESS:
      {
        var _activities3 = _objectSpread({}, state.activities);

        _activities3[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Activity Updated',
          activities: _activities3
        });
      }

    case definitions.UPDATE_ACTIVITY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_SESSION_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_SESSION_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_SESSION_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.SET_ACTIVITY_LOCATION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          location: action.payload
        });
      }

    case definitions.CREATE_SESSION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_SESSION_SUCCESS:
      {
        var sessions = _objectSpread({}, state.sessions);

        sessions[action.payload.id] = action.payload;
        sessions['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          sessions: sessions
        });
      }

    case definitions.CREATE_SESSION_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_SESSION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_SESSION_SUCCESS:
      {
        var _sessions = _objectSpread({}, state.sessions);

        _sessions[action.payload.id] = _objectSpread(_objectSpread({}, _sessions[action.payload.id]), action.payload);

        if (_sessions['current'] && _sessions['current'].id === action.payload.id) {
          _sessions['current'] = _objectSpread(_objectSpread({}, _sessions['current']), action.payload);
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Session Updated',
          sessions: _sessions
        });
      }

    case definitions.UPDATE_SESSION_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_ACTIVITY_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_ACTIVITY_SUCCESS:
      {
        var _activities4 = _objectSpread({}, state.activities);

        _activities4[action.payload.id] = action.payload;
        _activities4['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          activities: _activities4
        });
      }

    case definitions.GET_ACTIVITY_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SESSION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SESSION_SUCCESS:
      {
        var _sessions2 = _objectSpread({}, state.sessions);

        _sessions2[action.payload.id] = action.payload;
        _sessions2['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          sessions: _sessions2
        });
      }

    case definitions.GET_SESSION_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_UPCOMING_SESSIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_UPCOMING_SESSIONS_SUCCESS:
      {
        var _action$payload;

        var upcomingSessions = {};
        (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.forEach(function (session) {
          upcomingSessions[session.startsAt] = session;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          upcomingSessions: upcomingSessions
        });
      }

    case definitions.GET_UPCOMING_SESSIONS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_SESSION_DATA:
      {
        return INITIAL_STATE;
      }

    case definitions.SET_CURRENT_SESSION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentEvent: action.payload
        });
      }

    default:
      return state;
  }
};