import { useEffect, useRef } from 'react';

var useIsMounted = function useIsMounted() {
  var isMounted = useRef(false);
  useEffect(function () {
    isMounted.current = true;
    return function () {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

export default useIsMounted;