import { connect } from 'react-redux';
import { clearAuthErrors as _clearAuthErrors, clearAuthInfo as _clearAuthInfo, clearServiceCustomerInfo as _clearServiceCustomerInfo, clearProspectErrors as _clearProspectErrors, clearProspectInfo as _clearProspectInfo, clearProviderErrors as _clearProviderErrors, clearProviderInfo as _clearProviderInfo, clearSessionErrors as _clearSessionErrors, clearSessionInfo as _clearSessionInfo, clearBookingErrors as _clearBookingErrors, clearBookingInfo as _clearBookingInfo, clearAnalyticsErrors as _clearAnalyticsErrors, clearAnalyticsInfo as _clearAnalyticsInfo, clearPaymentErrors as _clearPaymentErrors, clearPaymentInfo as _clearPaymentInfo, clearServiceCustomerErrors as _clearServiceCustomerErrors, clearAssetsErrors as _clearAssetsErrors, clearSubscriptionInfo as _clearSubscriptionInfo, clearSubscriptionErrors as _clearSubscriptionErrors, clearReviewInfo as _clearReviewInfo, clearReviewErrors as _clearReviewErrors } from '../state/actions';
import FeedbackHandler from '../components/Layouts/FeedbackHandler';

var mapStateToProps = function mapStateToProps(state) {
  return {
    authErrors: state.auth.error,
    serviceCustomerErrors: state.serviceCustomer.error,
    prospectErrors: state.prospect.error,
    providerErrors: state.provider.error,
    sessionErrors: state.session.error,
    analyticsErrors: state.analytics.error,
    bookingErrors: state.booking.error,
    paymentErrors: state.payment.error,
    authInfo: state.auth.info,
    prospectInfo: state.prospect.info,
    providerInfo: state.provider.info,
    sessionInfo: state.session.info,
    bookingInfo: state.booking.info,
    analyticsInfo: state.analytics.info,
    paymentInfo: state.payment.info,
    serviceCustomerInfo: state.serviceCustomer.info,
    assetsErrors: state.assets.error,
    subscriptionErrors: state.subscriptions.error,
    subscriptionInfo: state.subscriptions.info,
    reviewErrors: state.reviews.error,
    reviewInfo: state.reviews.info
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    clearAuthErrors: function clearAuthErrors() {
      return dispatch(_clearAuthErrors());
    },
    clearProspectErrors: function clearProspectErrors() {
      return dispatch(_clearProspectErrors());
    },
    clearProviderErrors: function clearProviderErrors() {
      return dispatch(_clearProviderErrors());
    },
    clearSessionErrors: function clearSessionErrors() {
      return dispatch(_clearSessionErrors());
    },
    clearBookingErrors: function clearBookingErrors() {
      return dispatch(_clearBookingErrors());
    },
    clearAnalyticsErrors: function clearAnalyticsErrors() {
      return dispatch(_clearAnalyticsErrors());
    },
    clearPaymentErrors: function clearPaymentErrors() {
      return dispatch(_clearPaymentErrors());
    },
    clearAuthInfo: function clearAuthInfo() {
      return dispatch(_clearAuthInfo());
    },
    clearServiceCustomerInfo: function clearServiceCustomerInfo() {
      return dispatch(_clearServiceCustomerInfo());
    },
    clearServiceCustomerErrors: function clearServiceCustomerErrors() {
      return dispatch(_clearServiceCustomerErrors());
    },
    clearProspectInfo: function clearProspectInfo() {
      return dispatch(_clearProspectInfo());
    },
    clearProviderInfo: function clearProviderInfo() {
      return dispatch(_clearProviderInfo());
    },
    clearSessionInfo: function clearSessionInfo() {
      return dispatch(_clearSessionInfo());
    },
    clearBookingInfo: function clearBookingInfo() {
      return dispatch(_clearBookingInfo());
    },
    clearAnalyticsInfo: function clearAnalyticsInfo() {
      return dispatch(_clearAnalyticsInfo());
    },
    clearPaymentInfo: function clearPaymentInfo() {
      return dispatch(_clearPaymentInfo());
    },
    clearAssetsErrors: function clearAssetsErrors() {
      return dispatch(_clearAssetsErrors());
    },
    clearSubscriptionErrors: function clearSubscriptionErrors() {
      return dispatch(_clearSubscriptionErrors());
    },
    clearSubscriptionInfo: function clearSubscriptionInfo() {
      return dispatch(_clearSubscriptionInfo());
    },
    clearReviewErrors: function clearReviewErrors() {
      return dispatch(_clearReviewErrors());
    },
    clearReviewInfo: function clearReviewInfo() {
      return dispatch(_clearReviewInfo());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackHandler);