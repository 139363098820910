import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
var errorCodesToIgnore = /^40[0-9].*$/;
var validEnvs = ['production', 'preview']; // eslint-disable-next-line no-underscore-dangle

if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || 'DO NOT SEND',
    enabledReleaseStages: validEnvs,
    onError: function onError(event) {
      if (errorCodesToIgnore.test(event.originalError.status)) return false;
    },
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.NEXT_PUBLIC_VERCEL_ENV || "production"
  });
}

export default Bugsnag;