export var GET_PAYMENT_SOURCES_REQUEST = 'GET_PAYMENT_SOURCES_REQUEST';
export var GET_PAYMENT_SOURCES_SUCCESS = 'GET_PAYMENT_SOURCES_SUCCESS';
export var GET_PAYMENT_SOURCES_FAIL = 'GET_PAYMENT_SOURCES_FAIL';
export var CREATE_PAYMENT_SOURCE_REQUEST = 'CREATE_PAYMENT_SOURCE_REQUEST';
export var CREATE_PAYMENT_SOURCE_SUCCESS = 'CREATE_PAYMENT_SOURCE_SUCCESS';
export var CREATE_PAYMENT_SOURCE_FAIL = 'CREATE_PAYMENT_SOURCE_FAIL';
export var DELETE_PAYMENT_SOURCE_REQUEST = 'DELETE_PAYMENT_SOURCE_REQUEST';
export var DELETE_PAYMENT_SOURCE_SUCCESS = 'DELETE_PAYMENT_SOURCE_SUCCESS';
export var DELETE_PAYMENT_SOURCE_FAIL = 'DELETE_PAYMENT_SOURCE_FAIL';
export var CLEAR_PAYMENT_ERRORS = 'CLEAR_PAYMENT_ERRORS';
export var SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export var CLEAR_PAYMENT_INFO = 'CLEAR_PAYMENT_INFO';
export var CLEAR_PAYMENT_DATA = 'CLEAR_PAYMENT_DATA';
export var GET_ORDER_REQUEST = 'GET_ORDER_REQUEST';
export var GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export var GET_ORDER_FAIL = 'GET_ORDER_FAIL';
export var UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export var UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export var UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL';
export var CREATE_SUBSCRIPTION_ORDER_REQUEST = 'CREATE_SUBSCRIPTION_ORDER_REQUEST';
export var CREATE_SUBSCRIPTION_ORDER_SUCCESS = 'CREATE_SUBSCRIPTION_ORDER_SUCCESS';
export var CREATE_SUBSCRIPTION_ORDER_FAIL = 'CREATE_SUBSCRIPTION_ORDER_FAIL';
export var CREATE_SESSION_ORDER_REQUEST = 'CREATE_SESSION_ORDER_REQUEST';
export var CREATE_SESSION_ORDER_SUCCESS = 'CREATE_SESSION_ORDER_SUCCESS';
export var CREATE_SESSION_ORDER_FAIL = 'CREATE_SESSION_ORDER_FAIL';
export var INIT_ORDER_PAYMENT_REQUEST = 'INIT_ORDER_PAYMENT_REQUEST';
export var INIT_ORDER_PAYMENT_SUCCESS = 'INIT_ORDER_PAYMENT_SUCCESS';
export var INIT_ORDER_PAYMENT_FAIL = 'INIT_ORDER_PAYMENT_FAIL';