export var GET_USER_BOOKINGS_REQUEST = 'GET_USER_BOOKINGS_REQUEST';
export var GET_USER_BOOKINGS_SUCCESS = 'GET_USER_BOOKINGS_SUCCESS';
export var GET_USER_BOOKINGS_FAIL = 'GET_USER_BOOKINGS_FAIL';
export var GET_BOOKING_BY_ID_REQUEST = 'GET_BOOKING_BY_ID_REQUEST';
export var GET_BOOKING_BY_ID_SUCCESS = 'GET_BOOKING_BY_ID_SUCCESS';
export var GET_BOOKING_BY_ID_FAIL = 'CANCEL_BOOKING_FAIL';
export var CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST';
export var CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export var CANCEL_BOOKING_FAIL = 'CANCEL_BOOKING_FAIL';
export var CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST';
export var CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export var CREATE_BOOKING_FAIL = 'CREATE_BOOKING_FAIL';
export var CLEAR_BOOKING_ERRORS = 'CLEAR_BOOKING_ERRORS';
export var SET_BOOKING_INFO = 'SET_BOOKING_INFO';
export var CLEAR_BOOKING_INFO = 'CLEAR_BOOKING_INFO';
export var CLEAR_BOOKING_DATA = 'CLEAR_BOOKING_DATA';
export var GET_USER_SESSION_BOOKINGS_REQUEST = 'GET_USER_SESSION_BOOKINGS_REQUEST';
export var GET_USER_SESSION_BOOKINGS_SUCCESS = 'GET_USER_SESSION_BOOKINGS_SUCCESS';
export var GET_USER_SESSION_BOOKINGS_FAIL = 'GET_USER_SESSION_BOOKINGS_FAIL';