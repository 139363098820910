/* eslint-disable @typescript-eslint/no-shadow */
import { createAction } from '../../interfaces';
import * as definitions from '../../definitions';
export var redirectActionCreators = {
  redirectTo: function redirectTo(destination) {
    return createAction(definitions.REDIRECT_TO, destination);
  },
  clearRedirect: function clearRedirect() {
    return createAction(definitions.CLEAR_REDIRECT);
  }
};