import _classCallCheck from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
export var ReferralProgramActivationType;

(function (ReferralProgramActivationType) {
  ReferralProgramActivationType["ON_SUBSCRIBE"] = "on_subscribe";
})(ReferralProgramActivationType || (ReferralProgramActivationType = {}));

export var CodeRedemptionCreatedBy;

(function (CodeRedemptionCreatedBy) {
  CodeRedemptionCreatedBy["SIGNUP"] = "signup";
  CodeRedemptionCreatedBy["LINK"] = "link";
})(CodeRedemptionCreatedBy || (CodeRedemptionCreatedBy = {}));

export var ReferralProgramState;

(function (ReferralProgramState) {
  ReferralProgramState["UNACTIVATED"] = "unactivated";
  ReferralProgramState["ACTIVATED"] = "activated";
  ReferralProgramState["DEACTIVATED"] = "deactivated";
})(ReferralProgramState || (ReferralProgramState = {}));

export var RedeemShareCodeDto = function RedeemShareCodeDto() {
  _classCallCheck(this, RedeemShareCodeDto);

  _defineProperty(this, "code", void 0);

  _defineProperty(this, "serviceId", void 0);

  _defineProperty(this, "createdBy", void 0);
};