import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/prospect';
var INITIAL_STATE = {
  loading: false,
  processing: false,
  error: '',
  info: '',
  items: {}
};
export var ProspectReducer = function ProspectReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.CREATE_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_PROSPECT_SUCCESS:
      {
        var items = _objectSpread({}, state.items);

        items[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          items: items
        });
      }

    case definitions.CREATE_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CONFIRM_PROSPECT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CONFIRM_PROSPECT_SUCCESS:
      {
        var _items = _objectSpread({}, state.items);

        _items[action.payload.id] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          items: _items
        });
      }

    case definitions.CONFIRM_PROSPECT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_PROSPECT_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_PROSPECT_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_PROSPECT_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.CLEAR_PROSPECT_DATA:
      {
        return INITIAL_STATE;
      }

    default:
      return state;
  }
};