import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import cookie from 'js-cookie';
import * as definitions from '../../definitions/auth';
import { CookieName, authReducerMessages } from '../../utils/constants';
import { authCookieOptions } from '../../utils/auth';
var INITIAL_STATE = {
  loading: true,
  processing: false,
  loginRequestSent: false,
  info: '',
  error: '',
  users: {},
  consent: []
};
export var AuthReducer = function AuthReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.INITIAL_LOAD_COMPLETE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case definitions.CREATE_USER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_USER_EMAIL_NOTIFICATIONS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_USER_EMAIL_NOTIFICATIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_USER_EMAIL_NOTIFICATIONS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'User info saved successfully'
        });
      }

    case definitions.UPDATE_USER_EMAIL_OPTIONS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_USER_EMAIL_OPTIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_USER_EMAIL_OPTIONS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: authReducerMessages.UPDATE_USER_EMAIL_OPTIONS_SUCCESS
        });
      }

    case definitions.UPDATE_USER_PASSWORD_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_USER_PASSWORD_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_USER_PASSWORD_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'User info saved successfully'
        });
      }

    case definitions.CREATE_USER_SUCCESS:
      {
        var users = _objectSpread({}, state.users);

        users[action.payload.id] = action.payload;
        users['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          users: users
        });
      }

    case definitions.CREATE_USER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_USER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_USER_SUCCESS:
      {
        var _users = _objectSpread({}, state.users);

        _users[action.payload['id']] = action.payload;
        _users['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          users: _users
        });
      }

    case definitions.FINISH_SIGNUP_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.FINISH_SIGNUP_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.FINISH_SIGNUP_SUCCESS:
      {
        var _users2 = _objectSpread({}, state.users);

        _users2[action.payload['id']] = action.payload;
        _users2['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          users: _users2
        });
      }

    case definitions.UPDATE_USER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_USER_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_USER_SUCCESS:
      {
        var _users3 = _objectSpread({}, state.users);

        _users3[action.payload['id']] = action.payload.user;
        _users3['current'] = action.payload.user;
        var showFeedback = action.payload.showFeedback;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          users: _users3,
          info: showFeedback ? 'User info saved successfully' : ''
        });
      }

    case definitions.GET_USER_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.SIGNUP_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.SIGNUP_SUCCESS:
      {
        var _users4 = _objectSpread({}, state.users);

        _users4['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          users: _users4
        });
      }

    case definitions.SIGNUP_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.LOGIN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.LOGIN_SUCCESS:
      {
        var auth = _objectSpread({}, action.payload);

        var _users5 = _objectSpread({}, state.users);

        _users5['current'] = auth.user;
        cookie.set(CookieName.ACCESS_TOKEN, auth.access_token, authCookieOptions(2 * 60) // 2 hours in minute
        );
        cookie.set(CookieName.REFRESH_TOKEN, auth.refresh_token, authCookieOptions(7 * 60 * 24) // 7 days in minutes
        );
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          users: _users5
        });
      }

    case definitions.LOGIN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.REQUEST_LOGIN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true,
          loginRequestSent: false
        });
      }

    case definitions.REQUEST_LOGIN_SUCCESS:
      {
        cookie.set('confirm-email', action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          loginRequestSent: true
        });
      }

    case definitions.REQUEST_LOGIN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          loginRequestSent: false,
          error: action.payload
        });
      }

    case definitions.LOGOUT:
      {
        cookie.remove(CookieName.ACCESS_TOKEN);
        cookie.remove(CookieName.REFRESH_TOKEN);
        cookie.remove(CookieName.REFERRAL_PROGRAM_SIGNUP);
        cookie.remove(CookieName.REFERRAL_PROGRAM_PROVIDER);
        var retval = INITIAL_STATE;
        retval.loading = false;
        return retval;
      }

    case definitions.CLEAR_AUTH_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_AUTH_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_AUTH_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.USER_PROVIDER_DID_CHANGE:
      {
        var newState = _objectSpread({}, state);

        if (!newState.users['current']) throw new Error('Cannot create provider when not logged in. Please log out and try again.');
        newState.users['current'].provider = action.payload;
        return _objectSpread({}, newState);
      }

    case definitions.USER_PROVIDER_SERVICE_DID_CHANGE:
      {
        var _newState$users$curre, _newState$users$curre2;

        var _newState = _objectSpread({}, state);

        var services = (_newState$users$curre = _newState.users['current']) === null || _newState$users$curre === void 0 ? void 0 : (_newState$users$curre2 = _newState$users$curre.provider) === null || _newState$users$curre2 === void 0 ? void 0 : _newState$users$curre2.services;
        if (!services) throw new Error('Cannot create services when not already a provider. Please log out and try again.');
        var existingServiceIndex = services.findIndex(function (service) {
          var _action$payload;

          return service.id === (action === null || action === void 0 ? void 0 : (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.id);
        });

        if (existingServiceIndex >= 0) {
          services.splice(existingServiceIndex, 1, action === null || action === void 0 ? void 0 : action.payload);
        } else {
          services.push(action === null || action === void 0 ? void 0 : action.payload);
        }

        return _objectSpread({}, _newState);
      }

    case definitions.GET_USER_CONSENT_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_USER_CONSENT_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          consent: action.payload
        });
      }

    case definitions.GET_USER_CONSENT_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    default:
      return state;
  }
};