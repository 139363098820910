import cookie from 'js-cookie';
import { CookieName } from './constants';
import { baseUrl } from './helpers';
export var generateReferralLink = function generateReferralLink(providerSlug, shareCode) {
  return "".concat(baseUrl, "/").concat(providerSlug, "/r/").concat(shareCode.toLowerCase());
};
export var readSignupCookie = function readSignupCookie() {
  if (false) return;
  var str = cookie.get(CookieName.REFERRAL_PROGRAM_SIGNUP);
  if (!str) return;
  return JSON.parse(str);
};
export var setSignupCookie = function setSignupCookie(input) {
  if (false) return;
  var str = JSON.stringify(input);
  cookie.set(CookieName.REFERRAL_PROGRAM_SIGNUP, str);
};
export var readProviderCookie = function readProviderCookie() {
  if (false) return;
  var str = cookie.get(CookieName.REFERRAL_PROGRAM_PROVIDER);
  if (!str) return;
  return JSON.parse(str);
};
export var setProviderCookie = function setProviderCookie(input) {
  if (false) return;
  var str = JSON.stringify(input);
  cookie.set(CookieName.REFERRAL_PROGRAM_PROVIDER, str);
};