import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

/* eslint-disable @typescript-eslint/no-var-requires, import/no-extraneous-dependencies */
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { wrappedReducer } from './reducers';
import { createWrapper } from 'next-redux-wrapper';

var bindMiddleware = function bindMiddleware(middleware) {
  if (false) {
    var _require = require('redux-devtools-extension'),
        composeWithDevTools = _require.composeWithDevTools;

    return composeWithDevTools(applyMiddleware.apply(void 0, _toConsumableArray(middleware)));
  }

  return applyMiddleware.apply(void 0, _toConsumableArray(middleware));
};

var initStore = function initStore() {
  var store = createStore(wrappedReducer, bindMiddleware([thunkMiddleware]));

  if (true && window.Cypress) {
    ;
    window.store = store;
  }

  return store;
};

export var wrapper = createWrapper(initStore);