export var TopicType;

(function (TopicType) {
  TopicType["POSTS"] = "posts";
  TopicType["CAMPAIGNS"] = "campaigns";
  TopicType["SURVEYS"] = "surveys";
  TopicType["SESSIONS"] = "sessions";
  TopicType["EMAIL_CAMPAIGNS"] = "email_campaigns";
  TopicType["SUBSCRIPTION_PLANS"] = "subscription_plans";
  TopicType["REFERRALS"] = "referrals";
  TopicType["BANKING"] = "banking";
  TopicType["CONTACTS"] = "contacts";
})(TopicType || (TopicType = {}));