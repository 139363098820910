import * as definitions from '../../definitions';
var INITIAL_STATE = {
  to: null
};
export var RedirectReducer = function RedirectReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.REDIRECT_TO:
      {
        return {
          to: action.payload
        };
      }

    case definitions.CLEAR_REDIRECT:
      {
        return {
          to: null
        };
      }

    default:
      return state;
  }
};