import _objectWithoutProperties from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["successNotificationText"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions';
var INITIAL_STATE = {
  loading: false,
  processing: false,
  error: '',
  info: '',
  subscriptions: {},
  plans: {}
};
export var SubscriptionReducer = function SubscriptionReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.GET_SUBSCRIPTIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SUBSCRIPTIONS_SUCCESS:
      {
        var subscriptions = _objectSpread({}, state.subscriptions);

        var subscriptionList = _toConsumableArray(action.payload);

        subscriptionList.forEach(function (subscription) {
          subscriptions[subscription.id] = subscription;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          subscriptions: subscriptions
        });
      }

    case definitions.GET_SUBSCRIPTIONS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.SUBSCRIBE_TO_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.SUBSCRIBE_TO_PLAN_SUCCESS:
      {
        var _action$payload = action.payload,
            successNotificationText = _action$payload.successNotificationText,
            subscription = _objectWithoutProperties(_action$payload, _excluded);

        var _subscriptions = _objectSpread({}, state.subscriptions);

        _subscriptions['current'] = subscription;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: successNotificationText || 'Subscription Created',
          subscriptions: _subscriptions
        });
      }

    case definitions.SUBSCRIBE_TO_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SUBSCRIPTION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SUBSCRIPTION_SUCCESS:
      {
        var _subscriptions2 = _objectSpread({}, state.subscriptions);

        _subscriptions2['current'] = _objectSpread({}, action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          subscriptions: _subscriptions2
        });
      }

    case definitions.GET_SUBSCRIPTION_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.UPDATE_SUBSCRIPTION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_SUBSCRIPTION_SUCCESS:
      {
        var _subscriptions3 = _objectSpread({}, state.subscriptions);

        _subscriptions3['current'] = _objectSpread({}, action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Subscription Updated',
          subscriptions: _subscriptions3
        });
      }

    case definitions.UPDATE_SUBSCRIPTION_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CANCEL_SUBSCRIPTION_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CANCEL_SUBSCRIPTION_SUCCESS:
      {
        var _subscriptions4 = _objectSpread({}, state.subscriptions);

        _subscriptions4['current'] = _objectSpread({}, action.payload);
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Subscription Cancelled',
          subscriptions: _subscriptions4
        });
      }

    case definitions.CANCEL_SUBSCRIPTION_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_SUBSCRIPTION_PLAN_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SUBSCRIPTION_PLAN_SUCCESS:
      {
        var plans = _objectSpread({}, state.plans);

        var _action$payload2 = action.payload,
            plan = _action$payload2.plan,
            invoicePreview = _action$payload2.invoicePreview;
        plans[plan.id] = _objectSpread(_objectSpread({}, plan), {}, {
          invoicePreview: invoicePreview
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          plans: plans
        });
      }

    case definitions.GET_SUBSCRIPTION_PLAN_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_SUBSCRIPTION_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.CLEAR_SUBSCRIPTION_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    default:
      return state;
  }
};