import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/booking';
var INITIAL_STATE = {
  loading: false,
  loadingCurrentBooking: false,
  cancellingBooking: false,
  error: false,
  info: '',
  bookings: {},
  currentBooking: {},
  sessionBookings: {}
};
export var BookingReducer = function BookingReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.GET_USER_BOOKINGS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_USER_BOOKINGS_SUCCESS:
      {
        var bookingList = _toConsumableArray(action.payload);

        var bookings = {};
        bookingList.forEach(function (booking) {
          return bookings[booking.id] = booking;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          bookings: bookings
        });
      }

    case definitions.GET_USER_BOOKINGS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case definitions.GET_USER_SESSION_BOOKINGS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_USER_SESSION_BOOKINGS_SUCCESS:
      {
        var sessionBooking = action.payload[0];

        var sessionBookings = _objectSpread({}, state.sessionBookings);

        if (sessionBooking) sessionBookings[sessionBooking.session.id] = sessionBooking;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          sessionBookings: sessionBookings
        });
      }

    case definitions.GET_USER_SESSION_BOOKINGS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case definitions.GET_BOOKING_BY_ID_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingCurrentBooking: true,
          currentBooking: {}
        });
      }

    case definitions.GET_BOOKING_BY_ID_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingCurrentBooking: false,
          currentBooking: action.payload
        });
      }

    case definitions.GET_BOOKING_BY_ID_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loadingCurrentBooking: false
        });
      }

    case definitions.CANCEL_BOOKING_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cancellingBooking: true
        });
      }

    case definitions.CANCEL_BOOKING_SUCCESS:
      {
        var currentBooking = _objectSpread(_objectSpread({}, state.currentBooking), action.payload);

        return _objectSpread(_objectSpread({}, state), {}, {
          cancellingBooking: false,
          currentBooking: currentBooking
        });
      }

    case definitions.CANCEL_BOOKING_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          cancellingBooking: false
        });
      }

    case definitions.CREATE_BOOKING_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_BOOKING_SUCCESS:
      {
        var _bookings = _objectSpread({}, state.bookings);

        _bookings[action.payload.id] = action.payload;
        _bookings['current'] = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          info: 'Booking Confirmed!',
          bookings: _bookings
        });
      }

    case definitions.CREATE_BOOKING_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_BOOKING_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_BOOKING_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_BOOKING_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.CLEAR_BOOKING_DATA:
      {
        return INITIAL_STATE;
      }

    default:
      return state;
  }
};