import { createAction } from '../../interfaces';
import * as definitions from '../../definitions/navbar';
export var navbarActionCreators = {
  toggleNavbarAction: function toggleNavbarAction() {
    return createAction(definitions.TOGGLE_NAVBAR);
  },
  showNavbarAction: function showNavbarAction() {
    return createAction(definitions.SHOW_NAVBAR);
  },
  hideNavbarAction: function hideNavbarAction() {
    return createAction(definitions.HIDE_NAVBAR);
  }
};
export var toggleNavbar = function toggleNavbar() {
  return function (dispatch) {
    var toggleNavbarAction = navbarActionCreators.toggleNavbarAction;
    dispatch(toggleNavbarAction());
  };
};
export var showNavbar = function showNavbar() {
  return function (dispatch) {
    var showNavbarAction = navbarActionCreators.showNavbarAction;
    dispatch(showNavbarAction());
  };
};
export var hideNavbar = function hideNavbar() {
  return function (dispatch) {
    var hideNavbarAction = navbarActionCreators.hideNavbarAction;
    dispatch(hideNavbarAction());
  };
};