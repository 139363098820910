import { useRouter } from 'next/router';
import * as React from 'react';
var production = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

var _hsq;

if (true) {
  window.hsConversationSettings = {
    loadImmediately: false,
    inlineEmbedSelector: '',
    enableWidgetCookieBanner: false,
    disableAttachment: false
  };
  _hsq = window._hsq = window._hsq || [];

  if (production && !window.HubSpotConversations) {
    /**
     * In case any of the following widget functions is called before it's available,
     * we can call it when it becomes available.
     */
    window.HubSpotConversations = {
      widget: {
        load: function load(args) {
          var _this = this;

          setTimeout(function () {
            window.HubSpotConversations.widget.load.apply(_this, args);
          }, 50);
        },
        refresh: function refresh(args) {
          var _this2 = this;

          setTimeout(function () {
            window.HubSpotConversations.widget.refresh.apply(_this2, args);
          }, 50);
        },
        open: function open() {
          var _this3 = this;

          setTimeout(function () {
            window.HubSpotConversations.widget.open.apply(_this3);
          }, 50);
        },
        close: function close() {
          var _this4 = this;

          setTimeout(function () {
            window.HubSpotConversations.widget.close.apply(_this4);
          }, 50);
        },
        remove: function remove() {
          var _this5 = this;

          setTimeout(function () {
            window.HubSpotConversations.widget.remove.apply(_this5);
          }, 50);
        },
        status: function status() {
          return {
            loaded: false
          };
        }
      }
    };
  }
} // Create or reuse the global HubSpot object
// NOTE: Doubtful, but might need to go directly into useHubspot.
//       Just trying to avoid additional instantiations by placing it outside of the hook.
// const _hsq = (window._hsq = window._hsq || [])


export var useHubspot = function useHubspot() {
  var forceAsLoaded = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  // This will force useHubspot to be called every time the page changes
  var router = useRouter(); // We use this to know if the HubSpot script has been loaded
  // We use ref to avoid a re-render when Hubspot is marked as loaded

  var hsLoadedRef = React.useRef(forceAsLoaded); // Create and return a function to mark the HubSpot script as loaded

  var onLoad = function onLoad() {
    hsLoadedRef.current = true;
    window.HubSpotConversations.widget.load();
  }; // Run a layout effect any time the router pathname changes
  // NOTE: hsLoadedRef, in conjunction with the next/script tag,
  //       lazyOnload strategy, and onLoad prop, should prevent
  //       this useEffect from tracking the initial page load. This
  //       is intentional because the inital load of the HubSpot
  //       script does that by default. As such, we dont want to duplicate
  //       our pageview tracking.
  // Page change


  React.useEffect(function () {
    var _window;

    // Do not attempt to track a page view if HubSpot isn't loaded or the router isn't ready
    if (!hsLoadedRef.current || !router.isReady) return; // Track a page view in HubSpot

    _hsq.push(['setPath', router.pathname]);

    _hsq.push(['trackPageView']); // Load Hubspot chat


    if (!router.pathname.startsWith('/dashboard') || !((_window = window) !== null && _window !== void 0 && _window.HubSpotConversations)) return;
    var status = window.HubSpotConversations.widget.status();

    if (status.loaded) {
      window.HubSpotConversations.widget.refresh();
    }
  }, [router.isReady, router.pathname]);
  return {
    onLoad: onLoad
  };
};