export var TooltipType;

(function (TooltipType) {
  TooltipType["SESSION_FORM"] = "Session Form";
  TooltipType["PLAN_CREATION"] = "Plan Creation";
  TooltipType["REFERRAL_PROGRAM"] = "Referral Program";
  TooltipType["BETA_PROVIDER_SIGNUP"] = "Beta Provider Sign Up";
})(TooltipType || (TooltipType = {}));

export var SurveyType;

(function (SurveyType) {
  SurveyType["MARKETING"] = "marketing";
  SurveyType["PLAN_FIXED"] = "plan_fixed";
})(SurveyType || (SurveyType = {}));

export var StripeAccountErrors = {
  not_set: 'not-set',
  not_found: 'not-found',
  charges_enabled_false: 'charges-enabled-false',
  payouts_enabled_false: 'payouts-enabled-false',
  has_past_due_requirements: 'has-past-due-requirements'
};