import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/assets'; // At the moment this functions as a cache for permissions for any resource (post, session, whatever)
// We're assuming UUIDs for resource IDs are unique across different database tables/services which
// isn't optimal

var INITIAL_STATE = {
  loading: false,
  error: '',
  resources: {}
};
export var AssetsReducer = function AssetsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.CLEAR_ERRORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: ''
      });

    case definitions.SET_PLANS_FOR_ASSET_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.SET_PLANS_FOR_ASSET_SUCCESS:
      {
        var _action$payload = action.payload,
            resourceId = _action$payload.resourceId,
            resourceType = _action$payload.resourceType,
            serviceId = _action$payload.serviceId,
            planIds = _action$payload.planIds;

        var newResources = _objectSpread(_objectSpread({}, state.resources), {}, _defineProperty({}, resourceId, {
          plans: planIds,
          resourceType: resourceType,
          serviceId: serviceId
        }));

        return _objectSpread(_objectSpread({}, state), {}, {
          resources: newResources,
          loading: false
        });
      }

    case definitions.SET_PLANS_FOR_ASSET_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_PLANS_FOR_ASSET_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_PLANS_FOR_ASSET_SUCCESS:
      {
        var _action$payload2 = action.payload,
            plans = _action$payload2.plans,
            _resourceId = _action$payload2.resourceId,
            _resourceType = _action$payload2.resourceType,
            _serviceId = _action$payload2.serviceId;

        var _newResources = _objectSpread(_objectSpread({}, state.resources), {}, _defineProperty({}, _resourceId, {
          planIds: plans.map(function (p) {
            return p.id;
          }),
          plans: plans,
          resourceType: _resourceType,
          serviceId: _serviceId
        }));

        return _objectSpread(_objectSpread({}, state), {}, {
          resources: _newResources,
          loading: false
        });
      }

    case definitions.GET_PLANS_FOR_ASSET_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    default:
      return state;
  }
};