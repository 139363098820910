import _slicedToArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import debounce from 'lodash/debounce';
export var useDeviceInfo = function useDeviceInfo() {
  var _React$useState = React.useState({
    width: true ? window.innerWidth : undefined,
    height: true ? window.innerHeight : undefined
  }),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      windowSize = _React$useState2[0],
      setWindowSize = _React$useState2[1];

  var width = windowSize.width,
      height = windowSize.height;
  React.useEffect(function () {
    if (true) {
      var handleResize = debounce(function () {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }, 250);
      window.addEventListener('resize', handleResize);
      handleResize();
      return function () {
        return window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
  return {
    narrowScreen: width < 348,
    mobile: width <= 767,
    tablet: width > 767 && width < 1024,
    iPad: width >= 1024 && width < 1280,
    desktop: width >= 1280,
    tallScreen: height > 1400,
    width: width,
    height: height
  };
};