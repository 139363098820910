export var GET_PROVIDER_SERVICE_REQUEST = 'GET_PROVIDER_SERVICE_REQUEST';
export var GET_PROVIDER_SERVICE_SUCCESS = 'GET_PROVIDER_SERVICE_SUCCESS';
export var GET_PROVIDER_SERVICE_FAIL = 'GET_PROVIDER_SERVICE_FAIL';
export var GET_PROVIDER_REQUEST = 'GET_PROVIDER_REQUEST';
export var GET_PROVIDER_SUCCESS = 'GET_PROVIDER_SUCCESS';
export var GET_PROVIDER_FAIL = 'GET_PROVIDER_FAIL';
export var LOGGED_IN_PROVIDER_SUCCESS = 'LOGGED_IN_PROVIDER_SUCCESS';
export var CREATE_SERVICE_PROSPECT_REQUEST = 'CREATE_SERVICE_PROSPECT_REQUEST';
export var CREATE_SERVICE_PROSPECT_SUCCESS = 'CREATE_SERVICE_PROSPECT_SUCCESS';
export var CREATE_SERVICE_PROSPECT_FAIL = 'CREATE_SERVICE_PROSPECT_FAIL';
export var CONFIRM_SERVICE_PROSPECT_REQUEST = 'CONFIRM_SERVICE_PROSPECT_REQUEST';
export var CONFIRM_SERVICE_PROSPECT_SUCCESS = 'CONFIRM_SERVICE_PROSPECT_SUCCESS';
export var CONFIRM_SERVICE_PROSPECT_FAIL = 'CONFIRM_SERVICE_PROSPECT_FAIL';
export var GET_POSTS_REQUEST = 'GET_POSTS_REQUEST';
export var GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS';
export var GET_POSTS_FAIL = 'GET_POSTS_FAIL';
export var UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST = 'UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST';
export var UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST_SUCCESS = 'UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST';
export var UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST_FAIL = 'UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST_FAIL';
export var UNSUBSCRIBE_SERVICE_SUBSCRIBER_REQUEST = 'UNSUBSCRIBE_SERVICE_SUBSCRIBER_REQUEST';
export var UNSUBSCRIBE_SERVICE_SUBSCRIBER_REQUEST_SUCCESS = 'UNSUBSCRIBE_SERVICE_SUBSCRIBER_REQUEST';
export var UNSUBSCRIBE_SERVICE_SUBSCRIBER_REQUEST_FAIL = 'UNSUBSCRIBE_SERVICE_PROSPECT_REQUEST_FAIL';
export var UNSUBSCRIBE_PROVIDER_SERVICE_REQUEST = 'UNSUBSCRIBE_PROVIDER_SERVICE_REQUEST';
export var UNSUBSCRIBE_PROVIDER_SERVICE_REQUEST_SUCCESS = 'UNSUBSCRIBE_PROVIDER_SERVICE_REQUEST';
export var UNSUBSCRIBE_PROVIDER_SERVICE_REQUEST_FAIL = 'UNSUBSCRIBE_PROVIDER_SERVICE_REQUEST_FAIL';
export var GET_SURVEY_REQUEST = 'GET_SURVEY_REQUEST';
export var GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export var GET_SURVEY_FAIL = 'GET_SURVEY_FAIL';
export var GET_PLAN_SURVEY_ANSWERS_SUCCESS = 'GET_PLAN_SURVEY_ANSWERS_SUCCESS';
export var SUBMIT_SURVEY_REQUEST = 'SUBMIT_SURVEY_REQUEST';
export var SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export var SUBMIT_SURVEY_FAIL = 'SUBMIT_SURVEY_FAIL';
export var GET_SERVICE_PROSPECTS_REQUEST = 'GET_SERVICE_PROSPECTS_REQUEST';
export var GET_SERVICE_PROSPECTS_SUCCESS = 'GET_SERVICE_PROSPECTS_SUCCESS';
export var GET_SERVICE_PROSPECTS_FAIL = 'GET_SERVICE_PROSPECTS_FAIL';
export var GET_SERVICE_PROSPECT_COUNT_SUCCESS = 'GET_SERVICE_PROSPECT_COUNT_SUCCESS';
export var GET_POST_REQUEST = 'GET_POST_REQUEST';
export var GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export var GET_POST_FAIL = 'GET_POST_FAIL';
export var CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export var CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export var CREATE_POST_FAIL = 'CREATE_POST_FAIL';
export var UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST';
export var UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export var UPDATE_POST_PUBLISH_STATUS_SUCCESS = 'UPDATE_POST_PUBLISH_STATUS_SUCCESS';
export var UPDATE_POST_FAIL = 'UPDATE_POST_FAIL';
export var DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export var DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export var DELETE_POST_FAIL = 'DELETE_POST_FAIL';
export var EMAIL_POST_REQUEST = 'EMAIL_POST_REQUEST';
export var EMAIL_POST_SUCCESS = 'EMAIL_POST_SUCCESS';
export var EMAIL_POST_FAIL = 'EMAIL_POST_FAIL';
export var TEST_EMAIL_POST_REQUEST = 'TEST_EMAIL_POST_REQUEST';
export var TEST_EMAIL_POST_SUCCESS = 'TEST_EMAIL_POST_SUCCESS';
export var TEST_EMAIL_POST_FAIL = 'TEST_EMAIL_POST_FAIL';
export var EMAIL_POST_RESET = 'EMAIL_POST_RESET';
export var CLEAR_PROVIDER_ERRORS = 'CLEAR_PROVIDER_ERRORS';
export var SET_PROVIDER_INFO = 'SET_PROVIDER_INFO';
export var CLEAR_PROVIDER_INFO = 'CLEAR_PROVIDER_INFO';
export var CREATE_EMAIL_CAMPAIGN_REQUEST = 'CREATE_EMAIL_CAMPAIGN_REQUEST';
export var CREATE_EMAIL_CAMPAIGN_SUCCESS = 'CREATE_EMAIL_CAMPAIGN_SUCCESS';
export var CREATE_EMAIL_CAMPAIGN_FAIL = 'CREATE_EMAIL_CAMPAIGN_FAIL';
export var GET_EMAIL_CAMPAIGN_REQUEST = 'GET_EMAIL_CAMPAIGN_REQUEST';
export var GET_EMAIL_CAMPAIGN_SUCCESS = 'GET_EMAIL_CAMPAIGN_SUCCESS';
export var GET_EMAIL_CAMPAIGN_FAIL = 'GET_EMAIL_CAMPAIGN_FAIL';
export var GET_EMAIL_CAMPAIGN_LIST_REQUEST = 'GET_EMAIL_CAMPAIGN_LIST_REQUEST';
export var GET_EMAIL_CAMPAIGN_LIST_SUCCESS = 'GET_EMAIL_CAMPAIGN_LIST_SUCCESS';
export var GET_EMAIL_CAMPAIGN_LIST_FAIL = 'GET_EMAIL_CAMPAIGN_LIST_FAIL';
export var GET_SURVEY_RESULTS_REQUEST = 'GET_SURVEY_RESULTS_REQUEST';
export var GET_SURVEY_RESULTS_SUCCESS = 'GET_SURVEY_RESULTS_SUCCESS';
export var GET_SURVEY_RESULTS_FAIL = 'GET_SURVEY_RESULTS_FAIL';
export var GET_ALL_SURVEY_RESULTS_REQUEST = 'GET_ALL_SURVEY_RESULTS_REQUEST';
export var GET_ALL_SURVEY_RESULTS_SUCCESS = 'GET_ALL_SURVEY_RESULTS_SUCCESS';
export var GET_ALL_SURVEY_RESULTS_FAIL = 'GET_ALL_SURVEY_RESULTS_FAIL';
export var GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export var GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export var GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL';
export var CLEAR_PROVIDER_DATA = 'CLEAR_PROVIDER_DATA';
export var GET_SERVICE_PLANS_REQUEST = 'GET_SERVICE_PLANS_REQUEST';
export var GET_SERVICE_PLANS_SUCCESS = 'GET_SERVICE_PLANS_SUCCESS';
export var GET_SERVICE_PLANS_FAIL = 'GET_SERVICE_PLANS_FAIL';
export var GET_SUBSCRIPTION_PLAN_REQUEST = 'GET_SUBSCRIPTION_PLAN_REQUEST';
export var GET_SUBSCRIPTION_PLAN_SUCCESS = 'GET_SUBSCRIPTION_PLAN_SUCCESS';
export var GET_SUBSCRIPTION_PLAN_FAIL = 'GET_SUBSCRIPTION_PLAN_FAIL';
export var GET_SERVICE_SUBSCRIPTIONS_REQUEST = 'GET_SERVICE_SUBSCRIPTIONS_REQUEST';
export var GET_SERVICE_SUBSCRIPTIONS_SUCCESS = 'GET_SERVICE_SUBSCRIPTIONS_SUCCESS';
export var GET_SERVICE_SUBSCRIPTIONS_FAIL = 'GET_SERVICE_SUBSCRIPTIONS_FAIL';
export var GET_PROMO_TRUE_SERVICE_CUSTOMERS_REQUEST = 'GET_PROMO_TRUE_SERVICE_CUSTOMERS_REQUEST';
export var GET_PROMO_TRUE_SERVICE_CUSTOMERS_SUCCESS = 'GET_PROMO_TRUE_SERVICE_CUSTOMERS_SUCCESS';
export var GET_PROMO_TRUE_SERVICE_CUSTOMERS_FAIL = 'GET_PROMO_TRUE_SERVICE_CUSTOMERS_FAIL';
export var GET_SERVICE_CUSTOMERS_REQUEST = 'GET_SERVICE_CUSTOMERS_REQUEST';
export var GET_SERVICE_CUSTOMERS_SUCCESS = 'GET_SERVICE_CUSTOMERS_SUCCESS';
export var GET_SERVICE_CUSTOMERS_FAIL = 'GET_SERVICE_CUSTOMERS_FAIL';
export var CREATE_VERIFIED_SERVICE_PROSPECT_REQUEST = 'CREATE_VERIFIED_SERVICE_PROSPECT_REQUEST';
export var CREATE_VERIFIED_SERVICE_PROSPECT_SUCCESS = 'CREATE_VERIFIED_SERVICE_PROSPECT_SUCCESS';
export var CREATE_VERIFIED_SERVICE_PROSPECT_FAIL = 'CREATE_VERIFIED_SERVICE_PROSPECT_FAIL';
export var UPLOAD_SERVICE_PROSPECTS_REQUEST = 'UPLOAD_SERVICE_PROSPECTS_REQUEST';
export var UPLOAD_SERVICE_PROSPECTS_SUCCESS = 'UPLOAD_SERVICE_PROSPECTS_SUCCESS';
export var UPLOAD_SERVICE_PROSPECTS_FAIL = 'UPLOAD_SERVICE_PROSPECTS_FAIL';
export var UPDATE_PROVIDER_SERVICE_SUCCESS = 'UPDATE_PROVIDER_SERVICE_SUCCESS';
export var UPDATE_PROVIDER_SERVICE_FAIL = 'UPDATE_PROVIDER_SERVICE_FAIL';
export var CREATE_PROVIDER = 'CREATE_PROVIDER';
export var CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export var CREATE_PROVIDER_FAIL = 'CREATE_PROVIDER_FAIL';
export var CREATE_PROVIDER_SERVICE = 'CREATE_PROVIDER_SERVICE';
export var CREATE_PROVIDER_SERVICE_SUCCESS = 'CREATE_PROVIDER_SERVICE_SUCCESS';
export var CREATE_PROVIDER_SERVICE_FAIL = 'CREATE_PROVIDER_SERVICE_FAIL';
export var UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export var UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export var UPDATE_PROVIDER_FAIL = 'UPDATE_PROVIDER_FAIL';
export var SET_PROVIDER_SELECTED_SERVICE_ID = 'SET_PROVIDER_SELECTED_SERVICE_ID';
export var GET_SERVICE_TERMS_REQUEST = 'GET_SERVICE_TERMS_REQUEST';
export var GET_SERVICE_TERMS_SUCCESS = 'GET_SERVICE_TERMS_SUCCESS';
export var GET_SERVICE_TERMS_FAIL = 'GET_SERVICE_TERMS_FAIL';
export var CREATE_PLAN_REQUEST = 'CREATE_PLAN_REQUEST';
export var CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export var CREATE_PLAN_FAIL = 'CREATE_PLAN_FAIL';
export var GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
export var GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export var GET_PLAN_FAIL = 'GET_PLAN_FAIL';
export var UPDATE_PLAN_REQUEST = 'UPDATE_PLAN_REQUEST';
export var UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export var UPDATE_PLAN_FAIL = 'UPDATE_PLAN_FAIL';
export var DELETE_PLAN_REQUEST = 'DELETE_PLAN_REQUEST';
export var DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export var DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL';
export var DELETE_PLAN_FEATURE_REQUEST = 'DELETE_PLAN_FEATURE_REQUEST';
export var DELETE_PLAN_FEATURE_SUCCESS = 'DELETE_PLAN_FEATURE_SUCCESS';
export var DELETE_PLAN_FEATURE_FAIL = 'DELETE_PLAN_FEATURE_FAIL';
export var ACTIVATE_PLAN_REQUEST = 'ACTIVATE_PLAN_REQUEST';
export var ACTIVATE_PLAN_SUCCESS = 'ACTIVATE_PLAN_SUCCESS';
export var ACTIVATE_PLAN_FAIL = 'ACTIVATE_PLAN_FAIL';
export var DEACTIVATE_PLAN_REQUEST = 'DEACTIVATE_PLAN_REQUEST';
export var DEACTIVATE_PLAN_SUCCESS = 'DEACTIVATE_PLAN_SUCCESS';
export var DEACTIVATE_PLAN_FAIL = 'DEACTIVATE_PLAN_FAIL';
export var EMAIL_SESSION_ATTENDEES_REQUEST = 'EMAIL_SESSION_ATTENDEES_REQUEST';
export var EMAIL_SESSION_ATTENDEES_SUCCESS = 'EMAIL_SESSION_ATTENDEES_SUCCESS';
export var EMAIL_SESSION_ATTENDEES_FAIL = 'EMAIL_SESSION_ATTENDEES_FAIL';
export var GET_ACTIVE_REFERRAL_PROGRAM_REQUEST = 'GET_ACTIVE_REFERRAL_PROGRAM_REQUEST';
export var GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS = 'GET_ACTIVE_REFERRAL_PROGRAM_SUCCESS';
export var GET_ACTIVE_REFERRAL_PROGRAM_FAIL = 'GET_ACTIVE_REFERRAL_PROGRAM_FAIL';
export var CREATE_SURVEY_REQUEST = 'CREATE_SURVEY_REQUEST';
export var CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export var CREATE_SURVEY_FAIL = 'CREATE_SURVEY_FAIL';
export var UPDATE_SURVEY_REQUEST = 'UPDATE_SURVEY_REQUEST';
export var UPDATE_SURVEY_SUCCESS = 'UPDATE_SURVEY_SUCCESS';
export var UPDATE_SURVEY_FAIL = 'UPDATE_SURVEY_FAIL';
export var GET_SERVICE_MAILING_LIST_REQUEST = 'GET_SERVICE_MAILING_LIST_REQUEST';
export var GET_SERVICE_MAILING_LIST_SUCCESS = 'GET_SERVICE_MAILING_LIST_SUCCESS';
export var GET_SERVICE_MAILING_LIST_FAIL = 'GET_SERVICE_MAILING_LIST_FAIL';
export var CREATE_SERVICE_TERMS_REQUEST = 'CREATE_SERVICE_TERMS_REQUEST';
export var CREATE_SERVICE_TERMS_SUCCESS = 'CREATE_SERVICE_TERMS_SUCCESS';
export var CREATE_SERVICE_TERMS_FAIL = 'CREATE_SERVICE_TERMS_FAIL';
export var UPDATE_SERVICE_TERMS_REQUEST = 'UPDATE_SERVICE_TERMS_REQUEST';
export var UPDATE_SERVICE_TERMS_SUCCESS = 'UPDATE_SERVICE_TERMS_SUCCESS';
export var UPDATE_SERVICE_TERMS_FAIL = 'UPDATE_SERVICE_TERMS_FAIL';
export var PROVIDER_DID_CHANGE = 'PROVIDER_DID_CHANGE';
export var PROVIDER_SERVICE_DID_CHANGE = 'PROVIDER_SERVICE_DID_CHANGE';
export var CREATE_CONTENT_VIEW_REQUEST = 'CREATE_CONTENT_VIEW_REQUEST';
export var CREATE_CONTENT_VIEW_SUCCESS = 'CREATE_CONTENT_VIEW_SUCCESS';
export var CREATE_CONTENT_VIEW_FAIL = 'CREATE_CONTENT_VIEW_FAIL';
export var CREATE_PLAN_PROSPECT_REQUEST = 'CREATE_PLAN_PROSPECT_REQUEST';
export var CREATE_PLAN_PROSPECT_SUCCESS = 'CREATE_PLAN_PROSPECT_SUCCESS';
export var CREATE_PLAN_PROSPECT_FAIL = 'CREATE_PLAN_PROSPECT_FAIL';
export var GET_PLAN_PROSPECTS_REQUEST = 'GET_PLAN_PROSPECTS_REQUEST';
export var GET_PLAN_PROSPECTS_SUCCESS = 'GET_PLAN_PROSPECTS_SUCCESS';
export var GET_PLAN_PROSPECTS_FAIL = 'GET_PLAN_PROSPECTS_FAIL';
export var GET_REVENUE_REPORT_REQUEST = 'GET_REVENUE_REPORT_REQUEST';
export var GET_REVENUE_REPORT_SUCCESS = 'GET_REVENUE_REPORT_SUCCESS';
export var GET_REVENUE_REPORT_FAIL = 'GET_REVENUE_REPORT_FAIL';
export var UPDATE_SERVICE_PROSPECT_REQUEST = 'UPDATE_PROSPECT_REQUEST';
export var UPDATE_SERVICE_PROSPECT_SUCCESS = 'UPDATE_PROSPECT_SUCCESS';
export var UPDATE_SERVICE_PROSPECT_FAIL = 'UPDATE_PROSPECT_FAIL';