export var AssetResourceType;

(function (AssetResourceType) {
  AssetResourceType["POST"] = "post";
  AssetResourceType["SESSION"] = "session";
  AssetResourceType["ACTIVITY"] = "activity";
  AssetResourceType["SERVICE"] = "service";
})(AssetResourceType || (AssetResourceType = {}));

export var EntitlementGrantorType;

(function (EntitlementGrantorType) {
  EntitlementGrantorType["FREE"] = "free";
  EntitlementGrantorType["PRICE"] = "price";
  EntitlementGrantorType["OWNER"] = "owner";
})(EntitlementGrantorType || (EntitlementGrantorType = {}));