import { createAction } from '../../interfaces';
import * as definitions from '../../definitions/copy';
import copyServcie from '../../services/copy';
import { getErrorMessageFromErrorResponse, handleErrorResponse } from '../../utils/error';
export var copyActionCreators = {
  getTooltipRequest: function getTooltipRequest() {
    return createAction(definitions.GET_TOOLTIPS_REQUEST);
  },
  getTooltipSuccess: function getTooltipSuccess(payload) {
    return createAction(definitions.GET_TOOLTIPS_SUCCESS, payload);
  },
  getTooltipFail: function getTooltipFail(error) {
    return createAction(definitions.GET_TOOLTIPS_FAIL, error);
  }
};
export var getTooltips = function getTooltips() {
  return function (dispatch) {
    var getTooltipRequest = copyActionCreators.getTooltipRequest,
        getTooltipSuccess = copyActionCreators.getTooltipSuccess,
        getTooltipFail = copyActionCreators.getTooltipFail;
    dispatch(getTooltipRequest());
    return copyServcie.getTooltips().then(function (tooltips) {
      dispatch(getTooltipSuccess(tooltips));
    }, function (error) {
      var message = getErrorMessageFromErrorResponse(error);
      dispatch(getTooltipFail(message));
      handleErrorResponse(error, dispatch);
    });
  };
};