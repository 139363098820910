export var CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST';
export var CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export var CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL';
export var CLEAR_REVIEW_ERRORS = 'CLEAR_REVIEW_ERRORS';
export var CLEAR_REVIEW_INFO = 'CLEAR_REVIEW_INFO';
export var GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST';
export var GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
export var GET_REVIEW_FAIL = 'GET_REVIEW_FAIL';
export var GET_SERVICE_REVIEWS_REQUEST = 'GET_SERVICE_REVIEWS_REQUEST';
export var GET_SERVICE_REVIEWS_SUCCESS = "GET_SERVICE_REVIEWS_SUCCESS";
export var GET_SERVICE_REVIEWS_FAIL = 'GET_SERVICE_REVIEWS_FAIL';
export var UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST';
export var UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export var UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL';