export var GET_SERVICE_CUSTOMER_REQUEST = 'GET_SERVICE_CUSTOMER_REQUEST';
export var GET_SERVICE_CUSTOMER_SUCCESS = 'GET_SERVICE_CUSTOMER_SUCCESS';
export var GET_SERVICE_CUSTOMER_FAIL = 'GET_SERVICE_CUSTOMER_FAIL';
export var UPDATE_SERVICE_CUSTOMER_REQUEST = 'UPDATE_SERVICE_CUSTOMER_REQUEST';
export var UPDATE_SERVICE_CUSTOMER_SUCCESS = 'UPDATE_SERVICE_CUSTOMER_SUCCESS';
export var UPDATE_SERVICE_CUSTOMER_FAIL = 'UPDATE_SERVICE_CUSTOMER_FAIL';
export var CLEAR_SERVICE_CUSTOMER_ERRORS = 'CLEAR_SERVICE_CUSTOMER_ERRORS';
export var SET_SERVICE_CUSTOMER_INFO = 'SET_SERVICE_CUSTOMER_INFO';
export var CLEAR_SERVICE_CUSTOMER_INFO = 'CLEAR_SERVICE_CUSTOMER_INFO';
export var GET_SERVICE_CUSTOMER_BY_USERID_AND_SERVICEID_REQUEST = 'GET_SERVICE_CUSTOMER_BY_USERID_AND_SERVICEID_REQUEST';
export var GET_SERVICE_CUSTOMER_BY_USERID_AND_SERVICEID_SUCCESS = 'GET_SERVICE_CUSTOMER_BY_USERID_AND_SERVICEID_SUCCESS';
export var GET_SERVICE_CUSTOMER_BY_USERID_AND_SERVICEID_FAIL = 'GET_SERVICE_CUSTOMER_BY_USERID_AND_SERVICEID_FAIL';