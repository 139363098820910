export var GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export var GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export var GET_SUBSCRIPTIONS_FAIL = 'GET_SUBSCRIPTIONS_FAIL';
export var SUBSCRIBE_TO_PLAN_REQUEST = 'SUBSCRIBE_TO_PLAN_REQUEST';
export var SUBSCRIBE_TO_PLAN_SUCCESS = 'SUBSCRIBE_TO_PLAN_SUCCESS';
export var SUBSCRIBE_TO_PLAN_FAIL = 'SUBSCRIBE_TO_PLAN_FAIL';
export var GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST';
export var GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export var GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';
export var UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export var UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export var UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL';
export var CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export var CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export var CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
export var CLEAR_SUBSCRIPTION_ERRORS = 'CLEAR_SUBSCRIPTION_ERRORS';
export var CLEAR_SUBSCRIPTION_INFO = 'CLEAR_SUBSCRIPTION_INFO';