import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/analytics';
var INITIAL_STATE = {
  loading: false,
  error: '',
  info: '',
  eventStats: {},
  resources: {},
  metrics: {},
  topic: {},
  events: {}
};
export var AnalyticsReducer = function AnalyticsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.CLEAR_ANALYTICS_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.SET_ANALYTICS_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: action.payload
        });
      }

    case definitions.CLEAR_ANALYTICS_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.GET_EMAIL_SIGNUPS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_EMAIL_SIGNUPS_SUCCESS:
      {
        var emailSignUpList = _toConsumableArray(action.payload);

        var eventStats = {};
        emailSignUpList.forEach(function (a) {
          return eventStats[a.day] = a;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          eventStats: eventStats
        });
      }

    case definitions.GET_EMAIL_SIGNUPS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_TOPICS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_TOPICS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          topic: action.payload
        });
      }

    case definitions.GET_TOPICS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_METRICS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_METRICS_SUCCESS:
      {
        var metricList = _toConsumableArray(action.payload);

        var metrics = {};
        metricList.forEach(function (a) {
          return metrics[a.eventType] = a;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          metrics: metrics
        });
      }

    case definitions.GET_METRICS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_EVENTS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_EVENTS_SUCCESS:
      {
        var eventList = _toConsumableArray(action.payload);

        var events = {};
        eventList.forEach(function (a) {
          return events[a.id] = a;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          events: events
        });
      }

    case definitions.GET_EVENTS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false
        });
      }

    case definitions.GET_RESOURCES_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_RESOURCES_SUCCESS:
      {
        var resourceList = _toConsumableArray(action.payload);

        var resources = {};
        resourceList.forEach(function (a) {
          return resources[a.id] = a;
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          resources: resources
        });
      }

    case definitions.GET_RESOURCES_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.CLEAR_ANALYTICS_DATA:
      {
        return INITIAL_STATE;
      }

    default:
      return state;
  }
};