// Stripe, per April 13 2021: Possible values are incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid.
export var StripeSubscriptionStatus;

(function (StripeSubscriptionStatus) {
  StripeSubscriptionStatus["INCOMPLETE"] = "incomplete";
  StripeSubscriptionStatus["INCOMPLETE_EXPIRED"] = "incomplete_expired";
  StripeSubscriptionStatus["TRIALING"] = "trialing";
  StripeSubscriptionStatus["ACTIVE"] = "active";
  StripeSubscriptionStatus["PAST_DUE"] = "past_due";
  StripeSubscriptionStatus["CANCELED"] = "canceled";
  StripeSubscriptionStatus["UNPAID"] = "unpaid";
})(StripeSubscriptionStatus || (StripeSubscriptionStatus = {}));

export var stripeActiveSubscriptionStatuses = [StripeSubscriptionStatus.TRIALING, StripeSubscriptionStatus.ACTIVE, StripeSubscriptionStatus.PAST_DUE];
export var stripeInactiveSubscriptionStatuses = [StripeSubscriptionStatus.INCOMPLETE, StripeSubscriptionStatus.CANCELED, StripeSubscriptionStatus.INCOMPLETE_EXPIRED];
export var ICodeRedemptionCodeType;

(function (ICodeRedemptionCodeType) {
  ICodeRedemptionCodeType["SHARE"] = "share";
  ICodeRedemptionCodeType["COUPON"] = "coupon";
})(ICodeRedemptionCodeType || (ICodeRedemptionCodeType = {}));