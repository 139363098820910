export var INITIAL_LOAD_COMPLETE = 'INITIAL_LOAD_COMPLETE';
export var CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export var CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export var CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export var SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export var SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export var SIGNUP_FAIL = 'SIGNUP_FAIL';
export var GET_USER_REQUEST = 'GET_USER_REQUEST';
export var GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export var GET_USER_FAIL = 'GET_USER_FAIL';
export var FINISH_SIGNUP_REQUEST = 'FINISH_SIGNUP_REQUEST';
export var FINISH_SIGNUP_SUCCESS = 'FINISH_SIGNUP_SUCCESS';
export var FINISH_SIGNUP_FAIL = 'FINISH_SIGNUP_FAIL';
export var LOGIN_REQUEST = 'LOGIN_REQUEST';
export var LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export var LOGIN_FAIL = 'LOGIN_FAIL';
export var LOGOUT = 'LOGOUT';
export var CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export var SET_AUTH_INFO = 'SET_AUTH_INFO';
export var CLEAR_AUTH_INFO = 'CLEAR_AUTH_INFO';
export var USER_PROVIDER_DID_CHANGE = 'USER_PROVIDER_DID_CHANGE';
export var USER_PROVIDER_SERVICE_DID_CHANGE = 'USER_PROVIDER_SERVICE_DID_CHANGE';
export var UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export var UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export var UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export var UPDATE_USER_EMAIL_NOTIFICATIONS_REQUEST = 'UPDATE_USER_EMAIL_NOTIFICATIONS_REQUEST';
export var UPDATE_USER_EMAIL_NOTIFICATIONS_SUCCESS = 'UPDATE_USER_EMAIL_NOTIFICATIONS_SUCCESS';
export var UPDATE_USER_EMAIL_NOTIFICATIONS_FAIL = 'UPDATE_USER_EMAIL_NOTIFICATIONS_FAIL';
export var REQUEST_LOGIN_REQUEST = 'REQUEST_LOGIN_REQUEST';
export var REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export var REQUEST_LOGIN_FAIL = 'REQUEST_LOGIN_FAIL';
export var UPDATE_USER_EMAIL_OPTIONS_REQUEST = 'UPDATE_USER_EMAIL_OPTIONS_REQUEST';
export var UPDATE_USER_EMAIL_OPTIONS_SUCCESS = 'UPDATE_USER_EMAIL_OPTIONS_SUCCESS';
export var UPDATE_USER_EMAIL_OPTIONS_FAIL = 'UPDATE_USER_EMAIL_OPTIONS_FAIL';
export var UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export var UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export var UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';
export var GET_USER_CONSENT_REQUEST = 'GET_USER_CONSENT_REQUEST';
export var GET_USER_CONSENT_SUCCESS = 'GET_USER_CONSENT_SUCCESS';
export var GET_USER_CONSENT_FAIL = 'GET_USER_CONSENT_FAIL';