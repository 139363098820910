import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as definitions from '../../definitions/reviews';
var INITIAL_STATE = {
  loading: false,
  serviceReviews: {},
  error: false,
  info: '',
  reviews: [],
  currentReview: {}
};
export var ReviewReducer = function ReviewReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case definitions.CREATE_REVIEW_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.CREATE_REVIEW_SUCCESS:
      {
        var currentReview = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          currentReview: currentReview,
          info: 'Review sent successfully. Thank you!'
        });
      }

    case definitions.CREATE_REVIEW_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: action.payload
        });
      }

    case definitions.GET_REVIEW_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_REVIEW_SUCCESS:
      {
        var reviews = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          currentReview: reviews,
          loading: false
        });
      }

    case definitions.GET_REVIEW_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: action.payload,
          loading: false
        });
      }

    case definitions.GET_SERVICE_REVIEWS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.GET_SERVICE_REVIEWS_SUCCESS:
      {
        var serviceReviews = _objectSpread({}, state.serviceReviews);

        serviceReviews[action.payload.serviceId] = action.payload.reviews;
        return _objectSpread(_objectSpread({}, state), {}, {
          serviceReviews: serviceReviews,
          loading: false
        });
      }

    case definitions.GET_SERVICE_REVIEWS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: action.payload,
          loading: false
        });
      }

    case definitions.CLEAR_REVIEW_ERRORS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: ''
        });
      }

    case definitions.CLEAR_REVIEW_INFO:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          info: ''
        });
      }

    case definitions.UPDATE_REVIEW_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: true
        });
      }

    case definitions.UPDATE_REVIEW_SUCCESS:
      {
        var review = action.payload.review;

        var _reviews = _objectSpread({}, state.serviceReviews);

        var index = _reviews[action.payload.serviceId].findIndex(function (r) {
          return r.id === review.id;
        });

        _reviews[action.payload.serviceId][index] = review;
        return _objectSpread(_objectSpread({}, state), {}, {
          currentReview: review,
          reviews: _reviews,
          loading: false
        });
      }

    case definitions.UPDATE_REVIEW_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          error: action.payload,
          loading: false
        });
      }

    default:
      return state;
  }
};