// import { logout } from '../actions'
import Bugsnag from '../utils/bugsnag';
export var getErrorMessageFromErrorResponse = function getErrorMessageFromErrorResponse(error) {
  try {
    var _error$data, _error$data$response, _error$data2, _error$data2$response, _error$data3;

    if (Array.isArray(error === null || error === void 0 ? void 0 : (_error$data = error.data) === null || _error$data === void 0 ? void 0 : (_error$data$response = _error$data.response) === null || _error$data$response === void 0 ? void 0 : _error$data$response.message)) {
      return error.data.response.message.shift();
    }

    if (typeof (error === null || error === void 0 ? void 0 : (_error$data2 = error.data) === null || _error$data2 === void 0 ? void 0 : (_error$data2$response = _error$data2.response) === null || _error$data2$response === void 0 ? void 0 : _error$data2$response.message) === 'string') {
      return error.data.response.message;
    }

    return error === null || error === void 0 ? void 0 : (_error$data3 = error.data) === null || _error$data3 === void 0 ? void 0 : _error$data3.message;
  } catch (e) {
    var _error$data4, _error$data4$response, _error$data5;

    Bugsnag.notify({
      name: 'Failed to getErrorMessageFromErrorResponse() from error response',
      message: "error: ".concat(JSON.stringify({
        'error.data.response.message': error === null || error === void 0 ? void 0 : (_error$data4 = error.data) === null || _error$data4 === void 0 ? void 0 : (_error$data4$response = _error$data4.response) === null || _error$data4$response === void 0 ? void 0 : _error$data4$response.message,
        'error.data.message': error === null || error === void 0 ? void 0 : (_error$data5 = error.data) === null || _error$data5 === void 0 ? void 0 : _error$data5.message,
        'error.status': error === null || error === void 0 ? void 0 : error.status
      }))
    });
    return 'Something went wrong.';
  }
}; // const redirectToLogin = (dispatch) => {
//  if (dispatch) {
//    dispatch(logout())
//  }
//  if (
//    typeof window !== 'undefined' &&
//    window.location.pathname !== '/logout' &&
//    window.location.pathname !== '/login'
//  ) {
//    window.location.assign(
//      `/login?redirect=${encodeURIComponent(window.top.location.toString())}`,
//    )
//  }
// }

export var handleErrorResponse = function handleErrorResponse(error, dispatch) {
  if (error.status === 401) {
    ;

    (function () {
      return dispatch;
    }); // eslint-disable-line @typescript-eslint/no-unused-expressions
    // redirectTologin(dispatch)

  }
};