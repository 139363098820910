export var providerRedirectPath = '/dashboard';
export var userRedirectPath = '/subscriber-dashboard'; // Default path to redirect after login

export var defaultRedirectPath = providerRedirectPath; // TODO create a comprehensive list on backend

export var forbiddenUsernames = ['about', 'api', 'account', 'booking', 'robots.txt', 'contact', 'env', 'forgot-password', 'password', 'dashboard', 'login', 'logout', 'posts', 'post', 'pricing', 'prospect', 'provider-service', 'reserve', 'settings', 'schedule-embed', 'signup', 'update-password'];
export var calendarMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export var timeUnits = {
  seconds: 'seconds',
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  months: 'months'
};
export var currencySymbols = {
  cad: '$',
  usd: '$',
  eur: '€',
  gbp: '£'
};
export var currencyOptions = [{
  label: 'Select Currency',
  value: ''
}, {
  label: 'USD',
  value: 'usd'
}, {
  label: 'GBP',
  value: 'gbp'
}, {
  label: 'EUR',
  value: 'eur'
}, {
  label: 'CAD',
  value: 'cad'
}];
export var betaProviderBaseUrl = '/beta-provider-signup';
export var betaProviderProfileUrl = "".concat(betaProviderBaseUrl, "/profile");
export var servicePageTabs = {
  booking: 'booking',
  subscriptionPlans: 'subscription-plans',
  posts: 'posts'
};
export var CookieName;

(function (CookieName) {
  CookieName["ACCESS_TOKEN"] = "accessToken";
  CookieName["CONFIRM_EMAIL"] = "confirm-email";
  CookieName["REFRESH_TOKEN"] = "refreshToken";
  CookieName["REFERRAL_PROGRAM_SIGNUP"] = "referral-program-signup";
  CookieName["REFERRAL_PROGRAM_PROVIDER"] = "referral-program-provider";
  CookieName["SERVICE_PASSWORDS"] = "service-passwords";
})(CookieName || (CookieName = {}));

export var OAuthServiceName;

(function (OAuthServiceName) {
  OAuthServiceName["GOOGLE"] = "google";
})(OAuthServiceName || (OAuthServiceName = {}));

export var authReducerMessages = {
  UPDATE_USER_EMAIL_OPTIONS_SUCCESS: 'Successfully unsubscribed from weekly emails'
};
export var staticSessionResources = [{
  id: '0',
  title: 'How to schedule a session',
  url: 'https://www.notion.so/How-to-schedule-a-session-b938a5f856f94d03939168e4979fb453',
  order: 1
}, {
  id: '1',
  title: 'How to promote your session',
  url: 'https://www.notion.so/How-to-promote-your-session-989d8ef73fe64d2b85f229845bc93a47',
  order: 2
}];
export var staticReferralProgramResources = [{
  title: 'Generate word-of-mouth with a killer referral program',
  url: 'https://www.notion.so/Generate-word-of-mouth-with-a-killer-referral-program-d3dbb2a0bf5047888b04c609c13acae2',
  order: 1,
  id: '0'
}];
export var bugsnagIgnoredErrorCodes = [404];
export var googleCalendarUrl = "https://calendar.google.com/calendar/u/0/r/week/"; // also see apps/api/src/environments/constants.ts
// use https://bcrypt-generator.com/ to generate these

export var superUserEmailHashes = ['$2a$10$RO2ltusInLiVx1PET1Qo8etw3r426795k9I6BnJhY67Y4P1ZDDa6S', // bora
'$2a$10$PdtanQh2znq01BJA5MUMsu0xqmGgudje/HQVw7i6lw11nx8kYi182', // casen
'$2a$10$2O3Pcl9Ds0B26/7exmzMbeDBCuABCb.alxLBLCwy76GbpcMtR2fay', // tom
'$2a$12$iaTMskn9DV1Glbc0N1cGEuSMfHMRo1K8xq9Whx4uUf06/h7HSiEqG', // simon
'$2a$12$P/g2l3fPay1uupjzRGHaaOWNfYcrVhm.hrYF/IdbkNAG2Ej5JpECa', // cory
'$2a$12$aZtE6H8V9ZLryAN/aDLApe.D.RjaVn.y2um/eeK2UD5MeHT3iPTnu', // jason
'$2a$12$yViugF1H.sHhwY..v4LMO.4JMdcpPsvliyg811vzY.IDsZQq8xKw.', // cat
'$2a$12$blE3fHnr1/2FqRszWuIHQu1p1CBuNBBdIucAXkhyXxMU0/Cyhl1vy', // aldwin
'$2a$12$WgHWAPl.rVmP6aWRiHhV5.gB3kU9ZkmlyIioQpy4ycb5Ss3SuXQMe', // david
'$2a$12$CFPFhwhaXSzW/IKy7sIHGOvv/iaX.UQyIGOSZae0LCgYHLlqO2Liq', // chris
'$2a$12$Wvq7FdfJyf9GtJ3Dvrgmye1LBVJz8zXDltRo4yjhG1zlFze/PkIua', // njeri
'$2a$12$.ysrOIgZyAxuHohJOEoz3uguXwKDR/03YrbCevU00fjmVSHDZmOXG', // daisy
'$2a$12$mAGyUnm1dLHSAxQyxD3pdez7GgHTQveF4TlV2RN5CZXL61legPfZC', // johnpaul
'$2a$12$gsperhvF62GCk22epLwAIOPswfOoZlKSJF97FepoppD/Fn4YBMHpq', // chris+v1
'$2a$12$xYDGVFPGfc3K2KMftGkoj.j6W903wGGJsF/uAUdU8ccAJKqHp6W3q' // david+v1
];