import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { stripeActiveSubscriptionStatuses } from '../interfaces';
import { currencySymbols, forbiddenUsernames } from '../utils/constants';

var getDefinedUrl = function getDefinedUrl() {
  switch (process.env.NEXT_PUBLIC_VERCEL_ENV) {
    case 'preview':
      {
        return "web-git-".concat(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF, "-subkit.vercel.app");
      }

    case 'production':
      {
        return 'www.subkit.com';
      }

    default:
      {
        return process.env.NEXT_PUBLIC_VERCEL_URL;
      }
  }
};

var definedUrl = getDefinedUrl();
export var baseUrl = definedUrl.startsWith('http') ? definedUrl : "https://".concat(definedUrl);
export var normalizeLink = function normalizeLink(link) {
  try {
    return new URL(link).toString();
  } catch (e) {
    return new URL("https://".concat(link)).toString();
  }
};
export var truncate = function truncate(str) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 30;
  return str.length > length ? "".concat(str.substring(0, length), "...") : str;
};
export var getServiceIdFromUrl = function getServiceIdFromUrl() {
  if (false) return '';
  var chunks = window.location.pathname.split('/');
  return chunks.length > 2 ? chunks[2] : '';
};
export var validateEmail = function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export var validateName = function validateName(name) {
  var re = /[^a-zA-Z'\-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.,]/g;
  return !name || re.test(name) ? false : true;
};
export var validateSlug = function validateSlug(slug, allowHyphens) {
  var regex = allowHyphens ? /^[a-z0-9_-]+$/g : /^[a-z0-9_]+$/g;
  return regex.test(slug);
};
export var validateExistence = function validateExistence(val) {
  return val && val.length > 0;
};
export var validateSelectOption = function validateSelectOption(val) {
  return val && val.indexOf('Select') === -1;
};
export var validatePassword = function validatePassword(password) {
  var minLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
  return password.length >= minLength;
};
export var capitalize = function capitalize(text) {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
};
export var getFirstItemFromMap = function getFirstItemFromMap(items) {
  return Object.values(items).filter(function (item) {
    return Object.prototype.hasOwnProperty.call(item, 'id');
  })[0];
};
/**
 * Returns the index of an object in an array whose attribute value is given
 * @param array The array to be checked
 * @param attr The attribute name in the objects in the array to check
 * @param value The value to look for in the object attribute
 * @returns The index of the object whose attribute value matches that given
 */

export var indexOfAttr = function indexOfAttr(array, attr, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }

  return -1;
};
export var getQuery = function getQuery(query) {
  return query ? typeof query === 'string' ? query : query[0] : null;
};
export var handleFeedback = function handleFeedback(error, info) {
  var _error$data;

  if (!error && !info) return;
  var text = typeof error === 'string' ? error : 'Something Went Wrong!';

  if (error !== null && error !== void 0 && (_error$data = error.data) !== null && _error$data !== void 0 && _error$data.message) {
    var message = error.data.message;
    text = message.toString();
  } else if (typeof (error === null || error === void 0 ? void 0 : error.message) === 'string') {
    var _message = error.message;
    text = _message;
  }

  if (info) {
    toast.success(capitalize(info));
  } else {
    toast.error(capitalize(text));
  }
};
export var validateUsername = function validateUsername(username) {
  var re = /^(\d|\w)+$/;
  return !forbiddenUsernames.includes(username.toLocaleLowerCase()) && re.test(username);
};
export var copyTextToClipboard = function copyTextToClipboard(text) {
  var _navigator;

  if ((_navigator = navigator) !== null && _navigator !== void 0 && _navigator.clipboard) {
    navigator.clipboard.writeText(text);
    return true;
  }

  return false;
};
export var getCurrentURL = function getCurrentURL() {
  if (true) {
    return "".concat(window.location.protocol, "//").concat(window.location.hostname).concat(window.location.port ? ":".concat(window.location.port) : '');
  }

  return '';
};
export var pluralize = function pluralize(singular, plural, determinant) {
  return determinant === 1 ? singular : plural;
};
export var getPercentage = function getPercentage(part, whole) {
  return part && whole ? Math.round(part / whole * 100) : 0;
};
export var sumOfAttr = function sumOfAttr(array, attr) {
  return array.reduce(function (a, b) {
    return a + (b[attr] || 0);
  }, 0);
};
export var maxOfAttr = function maxOfAttr(array, attr) {
  return Math.max.apply(Math, _toConsumableArray(array.map(function (a) {
    return a[attr];
  })));
}; // Recurrsively compare two arrays

export var arrayEquals = function arrayEquals(a, b) {
  if (a === b) return true;
  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
  if (!a || !b || typeof a !== 'object' && typeof b !== 'object') return a === b;
  if (a.prototype !== b.prototype) return false;
  var keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;
  return keys.every(function (k) {
    return arrayEquals(a[k], b[k]);
  });
};
export var removeHTMLTags = function removeHTMLTags(withHTMLTags) {
  if (!withHTMLTags) return '';
  return withHTMLTags.replace(/<div.*[^>]>/gi, '') // eliminate text from widgets
  .replace(/(<([^>]+)>)/gi, ' ').replace(/\s+/gi, ' ').trim();
};
export var getAbsoluteURL = function getAbsoluteURL(req, setLocalhost) {
  var protocol = 'https:';
  var host = req ? req.headers['x-forwarded-host'] || req.headers['host'] : window.location.host;

  if (host.indexOf('localhost') > -1) {
    if (setLocalhost) host = setLocalhost;
    protocol = 'http:';
  }

  return {
    protocol: protocol,
    host: host,
    origin: protocol + '//' + host
  };
};
export var getTimeSlots = function getTimeSlots() {
  var minutesApart = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 15;
  var totalSlots = Math.floor(1440 / minutesApart);
  var startTime = moment().set({
    hour: 0,
    minute: 0,
    second: 0
  });
  var timeSlots = [];

  for (var i = 0; i < totalSlots; i++) {
    timeSlots.push({
      id: i,
      text: startTime.format('h:mm A')
    });
    startTime.add(minutesApart, 'minutes');
  }

  return timeSlots;
};
export var getFirstImageFromPost = function getFirstImageFromPost(post) {
  var imgSrcRegex = /<img[^>]+src="((https|http):\/\/[^">]+)"/g;
  var matches = imgSrcRegex.exec(post);
  return matches ? matches[1] : undefined;
};
export var mergeDateAndTime = function mergeDateAndTime(date, time) {
  var baseDate = moment(date);
  var baseTime = moment(time, 'h:mm A');
  baseDate.set({
    hour: baseTime.get('hour'),
    minute: baseTime.get('minute'),
    second: 0
  });
  return baseDate;
};
export var getMonthStartAndEndDates = function getMonthStartAndEndDates(date) {
  var startDate = moment(date);
  var endDate = moment(date);
  return {
    startsAt: startDate.startOf('month').add(1, 'day').toISOString(),
    endsAt: endDate.endOf('month').toISOString()
  };
}; // Turn a date into a human-readable string like 'June 11, 2021'

export var stringFromDate = function stringFromDate(date) {
  return new Intl.DateTimeFormat('default', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).format(date);
}; // Sort array of objects by date or any other primitive attribute of the objects (doesn't sort by objects)

export var sortObjectArrayByAttribute = function sortObjectArrayByAttribute(arr, attr, isDate, order) {
  var sortedArr = _toConsumableArray(arr);

  sortedArr.sort(function (a, b) {
    var aAttr = isDate ? new Date(a[attr]).getTime() : a[attr];
    var bAttr = isDate ? new Date(b[attr]).getTime() : b[attr];
    return aAttr > bAttr ? order === 'asc' ? 1 : -1 : bAttr > aAttr ? order === 'asc' ? -1 : 1 : 0;
  });
  return sortedArr;
};
export var getPathNameFromUrl = function getPathNameFromUrl(url) {
  if (!url) return '';
  if (url[0] === '/') return url.split('?')[0];
  var location = new URL(url);
  return location.pathname;
};
/**
 * Tells is a date is today
 * @param someDate date in question
 */

export var isToday = function isToday(someDate) {
  var today = new Date();
  return someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear();
};
export var eventStatsToChartValues = function eventStatsToChartValues(eventStats) {
  return eventStats.map(function (eventStat) {
    return {
      label: moment(eventStat.day).format('MMM D'),
      series: [parseInt(eventStat.count, 10)]
    };
  });
};
export var getPriceForAttendees = function getPriceForAttendees(amountCents, attendees, currency) {
  var participantPluralized = pluralize('participant', 'participants', attendees);
  return "".concat(getPriceString(amountCents, currency), " x ").concat(attendees, " ").concat(participantPluralized);
};
export var getPriceStringInterval = function getPriceStringInterval(price) {
  if (!price) return;
  var unitAmount = price.unitAmount,
      currency = price.currency,
      interval = price.interval,
      intervalCount = price.intervalCount;
  var pluralInterval = pluralize(interval, "".concat(interval, "s"), intervalCount);
  var intervalText = intervalCount > 1 ? "(".concat(intervalCount, " ").concat(pluralInterval, ")") : interval;
  var priceText = getPriceString(unitAmount, currency.toLowerCase());
  return interval ? "".concat(priceText, " /").concat(intervalText) : priceText;
};
export var getPriceString = function getPriceString(amountCents, currency) {
  var dollars = (amountCents || 0) / 100;
  return "".concat(amountCents < 0 ? '- ' : '').concat(currencySymbols[currency || 'usd']).concat(Math.abs(dollars).toFixed(Number.isInteger(dollars) ? 0 : 2));
};
/**
 * Check whether a user is subscribed to a service or not
 * @param userSubscriptions all subscriptions for the user
 * @param serviceId The id belonging to the service in question
 */

export var getCurrentSubscriptionFromService = function getCurrentSubscriptionFromService(userSubscriptions, serviceId) {
  if (!userSubscriptions || !serviceId) return;
  var activeSubs = userSubscriptions.filter(function (s) {
    return s.status && stripeActiveSubscriptionStatuses.includes(s.status);
  });
  return activeSubs.find(function (s) {
    var _s$plan, _s$plan$providerServi;

    return (s === null || s === void 0 ? void 0 : (_s$plan = s.plan) === null || _s$plan === void 0 ? void 0 : (_s$plan$providerServi = _s$plan.providerService) === null || _s$plan$providerServi === void 0 ? void 0 : _s$plan$providerServi.id) === serviceId;
  });
};
/**
 * Check whether a user is subscribed to a plan or not
 * @param userSubscriptions all subscriptions for the user
 * @param planId The id belonging to the plan in question
 */

export var getCurrentSubscriptionFromPlan = function getCurrentSubscriptionFromPlan(userSubscriptions, planId) {
  if (!userSubscriptions || !planId) return;
  var activeSubs = userSubscriptions.filter(function (s) {
    return s.status && stripeActiveSubscriptionStatuses.includes(s.status);
  });
  return activeSubs.find(function (s) {
    var _s$plan2;

    return ((_s$plan2 = s.plan) === null || _s$plan2 === void 0 ? void 0 : _s$plan2.id) === planId;
  });
};
/**
 * Return greeting based on the time of the day
 */

export var getGreeting = function getGreeting() {
  var period = 'morning';
  var currentHour = parseInt(moment().format('HH'), 10);

  if (currentHour >= 12 && currentHour < 17) {
    period = 'afternoon';
  } else if (currentHour >= 17 && currentHour <= 23) {
    period = 'evening';
  }

  return "Good ".concat(period);
};
/**
 * Return difference in minutes between two dates
 * @param startsAt earlier date to compare
 * @param endsAt later date to compare
 * @param unit unit in which to represent the difference
 * @returns the date difference in minutes
 */

export var getDateDifference = function getDateDifference(startsAt, endsAt, unit) {
  return "".concat(moment(endsAt).diff(moment(startsAt), unit), " ").concat(unit);
};
/**
 * This function returns a string that contains the address name concatenated to the full address
 *  only if the name does not already exist in the full address
 * @param fullAddress full address for the location whose text to display
 * @param name name of the location whose text to display
 * @returns a string containing the name concatenated to the full address
 */

export var getLocationText = function getLocationText(fullAddress, name) {
  if (!fullAddress) return name ? name : '';
  var checkedName = fullAddress.includes(name) ? '' : "".concat(name, ", ");
  return "".concat(checkedName).concat(fullAddress);
};
export var getLocalTime = function getLocalTime(serverTime) {
  var localTimezone = moment.tz.guess();
  var localTime = serverTime.clone().tz(localTimezone);
  return {
    localTime: localTime,
    localTimezone: localTimezone
  };
};
export var calculatePercentage = function calculatePercentage(dividend, divisor) {
  if (dividend === 0 && divisor === 0) return 0;
  if (dividend === 0) return 100;
  if (dividend !== 0) return Math.round((divisor - dividend) / dividend * 100);
};
export var getArrowIcon = function getArrowIcon(previousValue, currentValue) {
  if (previousValue === 0 && currentValue === 0) return null;
  if (currentValue > previousValue) return 'fas fa-arrow-up';
  if (currentValue < previousValue) return 'fas fa-arrow-down';
};
export var stripTagsFromString = function stripTagsFromString(str) {
  if (!str) {
    return '';
  }

  if (document && document.createElement) {
    var div = document.createElement('div');
    div.innerHTML = str;
    return div.textContent;
  }

  return str.replace(/(<([^>]+)>)/gi, '');
};
export var generateId = function generateId() {
  return Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
};
export var cleanUpICS = function cleanUpICS(icsString) {
  return decodeURIComponent(icsString.replace('data:text/calendar;charset=utf8,', ''));
};
export var redirectWithWindow = function redirectWithWindow(redirectUrl) {
  if (true) {
    window.location.href = redirectUrl;
  }
};
/**
 * This function returns a string that contains the user's initial of their last name concatenated to the first name
 * @param firstName full address for the location whose text to display
 * @param lastName name of the location whose text to display
 * @returns a string containing the initial of the last name concatenated to the first name
 */

export var getUserShortName = function getUserShortName(firstName, lastName) {
  if (!firstName) return '';
  return "".concat(capitalize(firstName)).concat(lastName ? " ".concat(capitalize(lastName.charAt(0))) : '', ".");
};
export var validateServiceName = function validateServiceName(str) {
  var re = /^[0-9A-Za-z.@_-\w+( \w+)]*$/;
  return re.test(str);
};
export var getFullName = function getFullName(firstName, lastName) {
  return !firstName && !lastName ? 'User' : [firstName, lastName].join(' ');
};