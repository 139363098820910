export var CLEAR_ANALYTICS_ERRORS = 'CLEAR_ANALYTICS_ERRORS';
export var CLEAR_ANALYTICS_INFO = 'CLEAR_ANALYTICS_INFO';
export var SET_ANALYTICS_INFO = 'SET_ANALYTICS_INFO';
export var GET_EMAIL_SIGNUPS_REQUEST = 'GET_EMAIL_SIGNUPS_REQUEST';
export var GET_EMAIL_SIGNUPS_SUCCESS = 'GET_EMAIL_SIGNUPS_SUCCESS';
export var GET_EMAIL_SIGNUPS_FAIL = 'GET_EMAIL_SIGNUPS_FAIL';
export var GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
export var GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export var GET_RESOURCES_FAIL = 'GET_RESOURCES_FAIL';
export var GET_METRICS_REQUEST = 'GET_METRICS_REQUEST';
export var GET_METRICS_SUCCESS = 'GET_METRICS_SUCCESS';
export var GET_METRICS_FAIL = 'GET_METRICS_FAIL';
export var CLEAR_ANALYTICS_DATA = 'CLEAR_ANALYTICS_DATA';
export var GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST';
export var GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export var GET_TOPICS_FAIL = 'GET_TOPICS_FAIL';
export var GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';
export var GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export var GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';