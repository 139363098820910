import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { combineReducers } from 'redux';
import { AuthReducer } from './auth';
import { BookingReducer } from './booking';
import { ProspectReducer } from './prospect';
import { ProviderReducer } from './provider';
import { SessionReducer } from './session';
import { CategoryReducer } from './categories';
import { HYDRATE } from 'next-redux-wrapper';
import { AnalyticsReducer } from './analytics';
import { NavbarReducer } from './navbar';
import { PaymentReducer } from './payments';
import { SubscriptionReducer } from './subscription';
import { RedirectReducer } from './redirect';
import { ServiceCustomerReducer } from './serviceCustomer';
import { ServiceProspectReducer } from './serviceProspect';
import { AssetsReducer } from './assets';
import { CopyReducer } from './copy';
import { ReviewReducer } from './review';
export * from './prospect';
export var allReducers = combineReducers({
  auth: AuthReducer,
  prospect: ProspectReducer,
  provider: ProviderReducer,
  session: SessionReducer,
  booking: BookingReducer,
  analytics: AnalyticsReducer,
  navbar: NavbarReducer,
  payment: PaymentReducer,
  categories: CategoryReducer,
  subscriptions: SubscriptionReducer,
  redirect: RedirectReducer,
  serviceCustomer: ServiceCustomerReducer,
  serviceProspect: ServiceProspectReducer,
  assets: AssetsReducer,
  copy: CopyReducer,
  reviews: ReviewReducer
});
export var wrappedReducer = function wrappedReducer(state, action) {
  if (action.type === HYDRATE) {
    var nextState = _objectSpread(_objectSpread({}, state), action.payload);

    if (state.count) nextState.count = state.count; // preserve count value on client side navigation

    return nextState;
  } else {
    return allReducers(state, action);
  }
};